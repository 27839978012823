"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _resizeEvent = require("element-ui/src/utils/resize-event");
/**
 * How to use
 * <el-table height="100px" v-el-height-adaptive-table="{bottomOffset: 50}">...</el-table>
 * el-table height is must be set
 * bottomOffset: 30(default)   // The height of the table from the bottom of the page.
 */

var doResize = function doResize(el, binding, vnode) {
  var $table = vnode.componentInstance;
  var value = binding.value;
  if (!$table.height) {
    throw new Error("el-$table must set the height. Such as height='100px'");
  }
  var bottomOffset = value && value.bottomOffset || 30;
  if (!$table) return;
  var height = window.innerHeight - el.getBoundingClientRect().top - bottomOffset;
  $table.$nextTick(function () {
    $table.layout.setHeight(height);
  });
};
var _default = exports.default = {
  bind: function bind(el, binding, vnode) {
    el.resizeListener = function () {
      doResize(el, binding, vnode);
    };
    // parameter 1 is must be "Element" type
    (0, _resizeEvent.addResizeListener)(window.document.body, el.resizeListener);
  },
  inserted: function inserted(el, binding, vnode) {
    doResize(el, binding, vnode);
  },
  unbind: function unbind(el) {
    (0, _resizeEvent.removeResizeListener)(window.document.body, el.resizeListener);
  }
};