"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _RestTable = _interopRequireDefault(require("@/components/RestTable.vue"));
var _goodForm = _interopRequireDefault(require("./goodForm.vue"));
var _default = exports.default = {
  components: {
    RestTable: _RestTable.default,
    GoodForm: _goodForm.default
  },
  data: function data() {
    return {
      // 顶部搜索框form数据
      formInline: {
        keyword: '',
        //搜索关键词
        active: '待审核'
      },
      dialogFormVisible: false,
      //是否显示新增产品form
      listLoading: true,
      is_day_nums: false,
      good_img: '',
      getimg: false
    };
  },
  methods: {
    // 根据id获取产品详情
    handleClick: function handleClick(item, type) {
      var _this = this;
      if (this.getimg == false) {
        this.$message.error('查看图片后再进行审核');
        return;
      }
      var type1 = '';
      if (type == '上架') {
        type1 = '通过';
      } else {
        type1 = '拒绝';
      }
      // 弹窗提示，如果用户确认则发请求
      this.$confirm("\u786E\u8BA4".concat(type1, "\u8BE5\u5546\u54C1\u5417"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _request.default)({
          url: "/system/goods/update_goods_status/",
          method: 'put',
          // params: { shop: this.$user.data.shop },// url参数
          data: {
            shop_id: _this.$user.data.shop,
            goods_id: item.id,
            active: type
          }
        }).then(function (res) {
          if (res.code == 200) {
            _this.$message.success('操作成功');
            _this.reload();
          } else {
            _this.$message.error('操作失败');
          }
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    // 顶部搜索框——根据商品名搜索
    handleFilter: function handleFilter(e) {
      // console.log(e.target.value, '搜索关键词')
      this.listLoading = true;
      // this.formInline.keyword = e.target.value
      // 调用子组件的goSearch方法
      this.$refs.target.handleFilter(this.formInline.keyword);
    },
    resetFilter: function resetFilter() {
      this.listLoading = true;
      this.formInline.keyword = '';
      this.$refs.target.resetFilter(this.formInline.keyword);
    },
    // 顶部添加按钮--显示新增产品form
    handleAdd: function handleAdd() {
      this.dialogFormVisible = true;
      this.getCharge(); // 获取扣费方式
    },
    getCharge: function getCharge() {
      var _this2 = this;
      (0, _request.default)({
        url: "/system/shopConfig/getConfig/",
        method: 'get',
        params: {
          shop_id: this.$user.data.shop
        }
      }).then(function (res) {
        if (res.data) {
          _this2.is_day_nums = res.data.is_day_nums;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    reload: function reload() {
      // 刷新页面
      window.location.reload();
    },
    getimgClick: function getimgClick(row) {
      var _this3 = this;
      this.getimg = true;
      // 获取图片进行展示
      (0, _request.default)({
        url: "/system/goods/goods_image_get/?goods_id=".concat(row.id),
        method: 'get'
      }).then(function (res) {
        if (res.code == 200) {
          // 保存为base64格式
          _this3.good_img = "data:image/png;base64,".concat(res.data);
          _this3.getimg = true;
          _this3.$message({
            dangerouslyUseHTMLString: true,
            message: '<img style="width:500px;height:500px" src="' + _this3.good_img + '"></img>',
            duration: 0,
            showClose: true
          });
        }
      });
    }
  }
};