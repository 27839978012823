"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'TypeTable',
  props: {
    tabledata1: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  }
};