"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm._v("\n  你好\n  "), _c("div", {
    ref: "chart1",
    staticClass: "chart1",
    staticStyle: {
      width: "100%",
      height: "400px"
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;