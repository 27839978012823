"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "login-container"
  }, [_c("el-card", {
    staticStyle: {
      "min-width": "800px"
    }
  }, [_c("h2", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("商户入驻")]), _vm._v(" "), _c("el-form", {
    ref: "Form",
    attrs: {
      model: _vm.document,
      rules: _vm.rules,
      "label-width": "180px",
      "label-position": "right"
    }
  }, [_c("el-divider", [_vm._v("账户信息")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "账户名",
      required: "",
      prop: "username"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.username,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "username", $$v);
      },
      expression: "document.username"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "账户密码",
      required: "",
      prop: "password"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.password,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "password", $$v);
      },
      expression: "document.password"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "账户密码",
      required: "",
      prop: "password"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.password2,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "password2", $$v);
      },
      expression: "document.password2"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "支付宝账号",
      required: "",
      prop: "alipay"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.alipay,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "alipay", $$v);
      },
      expression: "document.alipay"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "产品名字",
      required: "",
      prop: "product_name"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.product_name,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "product_name", $$v);
      },
      expression: "document.product_name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "产品链接",
      required: "",
      prop: "product_address"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.product_address,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "product_address", $$v);
      },
      expression: "document.product_address"
    }
  })], 1), _vm._v(" "), _c("el-divider", [_vm._v("公司信息")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "公司名称",
      required: "",
      prop: "company_name"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.company_name,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "company_name", $$v);
      },
      expression: "document.company_name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "公司E签宝合同章号",
      required: "",
      prop: "sealId"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.sealId,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "sealId", $$v);
      },
      expression: "document.sealId"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "法人电话",
      required: "",
      prop: "person_mobile"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.person_mobile,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "person_mobile", $$v);
      },
      expression: "document.person_mobile"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "法人姓名",
      required: "",
      prop: "person_name"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.person_name,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "person_name", $$v);
      },
      expression: "document.person_name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "法人身份证",
      required: "",
      prop: "person_card_code"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.person_card_code,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "person_card_code", $$v);
      },
      expression: "document.person_card_code"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "公司统一社会信用代码",
      required: "",
      prop: "company_scc"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.company_scc,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "company_scc", $$v);
      },
      expression: "document.company_scc"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "公司地址",
      required: "",
      prop: "company_address"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.company_address,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "company_address", $$v);
      },
      expression: "document.company_address"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "公司邮箱"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.company_email,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "company_email", $$v);
      },
      expression: "document.company_email"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "客服电话",
      prop: "kefu_mobile"
    }
  }, [_c("el-input", {
    on: {
      "!blur": function blur($event) {
        return _vm.autoSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.document.kefu_mobile,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "kefu_mobile", $$v);
      },
      expression: "document.kefu_mobile"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "公司营业执照",
      required: ""
    }
  }, [_c("el-upload", {
    attrs: {
      action: _vm.action,
      "list-type": "picture-card",
      accept: ".jpg,.png,.jpeg,.webp",
      "on-success": function onSuccess(response) {
        _vm.document.company_scc_picture = response;
      },
      limit: 1
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "法人身份证照片",
      required: ""
    }
  }, [_c("el-upload", {
    attrs: {
      action: _vm.action,
      "list-type": "picture-card",
      accept: ".jpg,.png,.jpeg,.webp",
      "on-success": function onSuccess(response) {
        _vm.document.person_picture.push(response);
      },
      limit: 3
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("上传身份证正面和身份证反面")])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "公司照片",
      required: ""
    }
  }, [_c("el-upload", {
    attrs: {
      action: _vm.action,
      "list-type": "picture-card",
      accept: ".jpg,.png,.jpeg,.webp",
      "on-success": function onSuccess(response) {
        _vm.document.company_picture.push(response);
      },
      limit: 5
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("起码上传一张公司照片")])], 1), _vm._v(" "), _c("el-divider", [_vm._v("充值信息")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "充值方式"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.document.recharge,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "recharge", $$v);
      },
      expression: "document.recharge"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "预缴费"
    }
  }, [_vm._v("预缴费")])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("el-button", {
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("提交审核")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "info"
    },
    on: {
      click: _vm.clearCookie
    }
  }, [_vm._v("重新填写")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;