"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: "market",
  data: function data() {
    return {
      value: [],
      options: [{
        value: '3C',
        label: '3C办公',
        children: [{
          value: '3c_mobile',
          label: '手机'
        }, {
          value: '3c_pc',
          label: '电脑'
        }, {
          value: '3c_camera',
          label: '摄影'
        }, {
          value: '3c_game_console',
          label: '游戏机'
        }, {
          value: '3c_office',
          label: '办公设备'
        }, {
          value: '3c_other',
          label: '3C-其他'
        }]
      }, {
        value: 'IOT',
        label: 'IOT',
        children: [{
          value: 'iot_auto_container',
          label: '售卖柜'
        }, {
          value: 'iot_stage',
          label: '驿站'
        }, {
          value: 'iot_other',
          label: 'IOT-其他'
        }]
      }, {
        value: 'NE',
        label: '新能源',
        children: [{
          value: 'ne_battery',
          label: '电池'
        }, {
          value: 'ne_car',
          label: '汽车'
        }, {
          value: 'ne_electric_car_logistic',
          label: '快递配送电动两轮车'
        }, {
          value: 'ne_electric_car_community',
          label: '城市共享电动两轮'
        }, {
          value: 'ne_other',
          label: '新能源-其他'
        }]
      }, {
        value: 'GENERAL',
        label: '泛行业',
        children: [{
          value: 'general_furniture',
          label: '家具'
        }, {
          value: 'general_tv',
          label: '家电'
        }, {
          value: 'general_house',
          label: '房屋'
        }, {
          value: 'general_decoration',
          label: '二手奢侈品或工艺品'
        }, {
          value: 'general_other',
          label: '泛其他'
        }]
      }, {
        value: 'EDUCATION',
        label: '教奇培训',
        children: [{
          value: 'education_vocational_training',
          label: '职业教培'
        }, {
          value: 'quality_education',
          label: '素质教育'
        }]
      }, {
        value: 'PETS',
        label: '宠物',
        children: [{
          value: 'adoption_installment',
          label: '颔养分期'
        }]
      }]
    };
  },
  methods: {
    handleChange: function handleChange(value) {
      console.log(value);
    }
  }
};