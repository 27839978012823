"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RestTable = _interopRequireDefault(require("@/components/RestTable.vue"));
var _default = exports.default = {
  // eslint-disable-next-line vue/no-unused-components
  components: {
    RestTable: _RestTable.default
  },
  methods: {
    handleFilter: function handleFilter() {
      this.$refs.target.handleFilter();
    },
    handleClick: function handleClick(row) {
      this.$router.push({
        name: 'preRegisterDetail',
        query: {
          id: row.id
        }
      });
    }
  }
};