"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.search");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container",
    staticStyle: {
      padding: "25px"
    }
  }, [_c("div", [_c("el-input", {
    staticClass: "filter-item",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "输入角色名称进行搜索"
    },
    nativeOn: {
      keyup: function keyup($event) {
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm._v(" "), _vm.checkPermission(["role_create"]) ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus",
      size: "small"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("新增")]) : _vm._e()], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }, {
      name: "el-height-adaptive-table",
      rawName: "v-el-height-adaptive-table",
      value: {
        bottomOffset: 50
      },
      expression: "{ bottomOffset: 50 }"
    }],
    staticStyle: {
      width: "100%",
      "margin-top": "20px"
    },
    attrs: {
      data: _vm.tableData,
      "highlight-current-row": "",
      "row-key": "id",
      height: "100",
      stripe: "",
      border: "",
      "header-cell-style": {
        color: "#fff",
        fontWeight: "700",
        backgroundColor: "#409eff"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      label: "角色名称",
      width: "220"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(_vm._s(scope.row.name))];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "header-center",
      label: "角色描述"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(_vm._s(scope.row.description))];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "small",
            icon: "el-icon-edit",
            disabled: !_vm.checkPermission(["role_update"])
          },
          on: {
            click: function click($event) {
              return _vm.handleEdit(scope);
            }
          }
        }, [_vm._v("编辑")]), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "danger",
            size: "small",
            icon: "el-icon-delete",
            disabled: !_vm.checkPermission(["role_delete"])
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(scope);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      title: _vm.dialogType === "edit" ? "编辑角色" : "新增角色"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.role,
      "label-width": "80px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "角色名称"
    },
    model: {
      value: _vm.role.name,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "name", $$v);
      },
      expression: "role.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "描述"
    }
  }, [_c("el-input", {
    attrs: {
      autosize: {
        minRows: 2,
        maxRows: 4
      },
      type: "textarea",
      placeholder: "角色描述"
    },
    model: {
      value: _vm.role.description,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "description", $$v);
      },
      expression: "role.description"
    }
  })], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "数据权限"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "90%"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.role.datas,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "datas", $$v);
      },
      expression: "role.datas"
    }
  }, _vm._l(_vm.dataOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.role.datas == "自定义",
      expression: "role.datas == '自定义'"
    }],
    attrs: {
      label: "权限范围"
    }
  }, [_c("el-tree", {
    ref: "depts_tree",
    staticClass: "filter-tree",
    attrs: {
      data: _vm.orgData,
      "show-checkbox": "",
      "node-key": "id",
      "default-expand-all": "",
      "highlight-current": "",
      "expand-on-click-node": false
    },
    model: {
      value: _vm.role.depts,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "depts", $$v);
      },
      expression: "role.depts"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "功能权限"
    }
  }, [_c("el-tree", {
    ref: "tree",
    staticClass: "permission-tree",
    attrs: {
      "check-strictly": _vm.checkStrictly,
      data: _vm.routes,
      "show-checkbox": "",
      "node-key": "id"
    },
    model: {
      value: _vm.role.perms,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "perms", $$v);
      },
      expression: "role.perms"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmRole
    }
  }, [_vm._v("确认")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;