"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.userData,
      border: "",
      stripe: "",
      "header-cell-style": {
        fontWeight: "700",
        backgroundColor: "#f5f7fa"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "60"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "姓名"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "phone",
      label: "手机号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "shopNum",
      label: "签约店铺"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "signNum",
      label: "签约单数"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "signMoney",
      label: "签约金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n            " + _vm._s(scope.row.signMoney) + "元\n            ")];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;