"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _default = exports.default = {
  name: 'RestTable',
  components: {
    Pagination: _index.default
  },
  props: {
    model: {
      type: String,
      required: true
    },
    keyword: {
      type: String,
      default: ''
    },
    active: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      document: {},
      dataList: {
        count: 0
      },
      roles: [],
      listLoading: true,
      dialogVisible: false,
      listQuery: {
        page: 1,
        page_size: 20
      },
      filterOrgText: '',
      treeLoding: false,
      orgData: []
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _request.default)({
        url: this.model,
        method: 'get',
        params: Object.assign({
          shop: this.$user.data.shop,
          name: this.keyword,
          active: this.active
        }, this.listQuery)
      }).then(function (response) {
        if (response.data) {
          _this.dataList = response.data;
        }
        _this.listLoading = false;
      });
    },
    // 重置搜索条件
    resetFilter: function resetFilter() {
      this.listQuery = {
        page: 1,
        page_size: 20
      };
      this.getList();
    },
    // 设置搜索条件
    setFilter: function setFilter(newFilter) {
      this.listQuery = Object.assign(newFilter, {
        page: this.listQuery.page,
        page_size: 20
      });
    },
    // 搜索
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleClick: function handleClick(row) {
      this.$router.push({
        name: 'preRegisterDetail',
        query: {
          id: row.id
        }
      });
    },
    // 搜索方法——根据关键字搜索【还不通】
    goSearch: function goSearch() {
      console.log('goSearch', this.keyword);
      // 发请求提交数据给后台
    }
  }
};