"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _request = _interopRequireDefault(require("@/utils/request"));
var _default = exports.default = {
  name: 'FileImage',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      document: {},
      attachments: [],
      listLoading: true
    };
  },
  created: function created() {
    var _this = this;
    (0, _request.default)({
      url: "/File/".concat(this.id, "/"),
      method: 'get'
    }).then(function (response) {
      if (response.data) {
        _this.document = response.data;
      }
      _this.listLoading = false;
    });
  }
};