"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("img", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      src: _vm.document.file,
      alt: _vm.document.name
    }
  });
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;