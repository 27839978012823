"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RestTable = _interopRequireDefault(require("@/components/RestTable.vue"));
var _default = exports.default = {
  components: {
    RestTable: _RestTable.default
  },
  data: function data() {
    return {
      search: '',
      // 顶部搜索框form数据
      formInline: {
        keyword: '' //搜索关键词
      }
    };
  },
  methods: {
    handleClick: function handleClick(item) {
      this.$router.push({
        name: 'shopDetail',
        query: {
          id: item.id
        }
      });
    },
    handleFilter: function handleFilter(e) {
      // this.$refs.target.handleFilter()
      this.listLoading = true;
      this.formInline.keyword = e.target.value;
      // 调用子组件的goSearch方法
      this.$refs.target.goSearch(this.formInline.keyword);
    }
  }
};