"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/www/wwwroot/deduct_node/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/deduct_node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.object.values");
var _vuex = require("vuex");
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request1"));
var _request3 = _interopRequireDefault(require("@/utils/request3"));
var _BarChart = _interopRequireDefault(require("@/components/chart/BarChart.vue"));
var _LineChart = _interopRequireDefault(require("@/components/chart/LineChart.vue"));
var _formatMixin = require("@/utils/formatMixin");
var _ShopTable = _interopRequireDefault(require("@/components/chart/ShopTable.vue"));
var _TypeTable = _interopRequireDefault(require("@/components/chart/TypeTable.vue"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  name: 'Dashboard',
  mixins: [_formatMixin.formatMixin],
  components: {
    BarChart: _BarChart.default,
    LineChart: _LineChart.default,
    ShopTable: _ShopTable.default,
    TypeTable: _TypeTable.default,
    Pagination: _index.default
  },
  mounted: function mounted() {
    // 从路由获取id
    var id = this.$route.query.id;
    this.queryid = this.$route.query.id;
    window.addEventListener('resize', this.handleResize);

    // 获取用户信息，日月活跃，银行卡列表，收支数据
    this.getMainList(id);
    // 获取交易商户列表数据
    // this.getMerchantList(id)
    // 获取全部店铺列表
    this.getAllList(id);
    this.getTagList();
    setTimeout(this.getlistByType(id, 1, this.formlist1), 1000);
    setTimeout(this.getAllShop(id), 1500);
    // setTimeout(this.getTagList(), 2000)
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    addShop: function addShop() {
      var _this = this;
      if (this.value > 0 && this.order_from != '') {
        (0, _request.default)({
          url: "/system/report/add_report_shop/",
          method: 'put',
          data: {
            order_from: this.order_from,
            tag_id: this.value
          }
        }).then(function (res) {
          if (res.code == 200) {
            _this.dialogFormVisible = false;
          } else {
            _this.$message({
              message: res.msg,
              type: 'error'
            });
            _this.order_from = '';
          }
          // console.log(res)
        });
      } else {
        this.$message({
          message: '参数错误',
          type: 'error'
        });
        this.dialogFormVisible = false;
      }

      // console.log(this.value)
    },
    goAddShop: function goAddShop(order_from) {
      var _this2 = this;
      this.order_from = order_from;
      // console.log(this.order_from)
      (0, _request.default)({
        url: "/system/report/check_report_shop/",
        method: 'put',
        data: {
          order_from: order_from
        }
      }).then(function (res) {
        console.log(res);
        if (res.code == 200) {
          _this2.dialogFormVisible = true;
        } else {
          _this2.$message({
            message: res.msg,
            type: 'error'
          });
          _this2.order_from = '';
        }
        // console.log(res)
      });

      // this.dialogFormVisible=true
      // console.log(order_from)
    },
    goBack: function goBack() {
      this.$router.go(-1);
    },
    handleResize: function handleResize() {
      if (this.$refs.barChart1) this.$refs.barChart1.resize();
      if (this.$refs.barChart2) this.$refs.barChart2.resize();
      if (this.$refs.lineChart1) this.$refs.lineChart1.resize();
      if (this.$refs.lineChart2) this.$refs.lineChart2.resize();
    },
    getMainList: function getMainList(id) {
      var _this3 = this;
      this.isLoading = true;
      (0, _request2.default)({
        url: "/get_report_hearder",
        method: 'post',
        data: {
          record_id: id
        }
      }).then(function (res) {
        // 银行卡列表
        _this3.bankList = Object.values(res.data.bankList);
        // 日活跃数据
        _this3.hourly_totals = res.data.hourly_totals;
        // 最长未使用天数
        _this3.longest_missing_sequence = res.data.longest_missing_sequence;
        // 活跃率
        _this3.missing_percentage = res.data.missing_percentage;
        // 月均收入/支出数据
        _this3.monthly_money_totals = Object.values(res.data.monthly_money_totals);
        // 月活跃数据
        _this3.monthly_totals = res.data.monthly_totals;
        // 用户信息
        _this3.userInfo = res.data.userInfo;
        // 报告类型
        _this3.type = res.data.type;
        // 交易时间段
        _this3.time_des = res.data.time_des;
        // 用于图表展示
        _this3.timedata.series[0].data = _this3.hourly_totals;
        _this3.monthdata.series[0].data = _this3.monthly_totals;
        _this3.isLoading = false;
      });
    },
    handleClick: function handleClick(tab) {
      console.log(tab.index);
      this.formlist1 = [];
      this.getlistByType(this.queryid, parseInt(tab.index) + 1, this.formlist1);
    },
    // 根据type类别获取列表数据
    getlistByType: function getlistByType(id, type, arr) {
      var _this4 = this;
      (0, _request2.default)({
        url: "/get_report_from_list",
        method: 'post',
        data: {
          record_id: id,
          type: type
        }
      }).then(function (res) {
        // 把res.data中的每一项的第二个数值取出来，统一放到formlist1中
        res.data.map(function (item) {
          item[1].money = _this4.formatNum(item[1].money, 2);
          arr.push(item[1]);
        });
      });
    },
    // 获取全部店铺列表
    getAllShop: function getAllShop(id) {
      this.getTypeList('先用后付', id, this.shopNameList1);
      this.getTypeList('租赁', id, this.shopNameList2);
      this.getTypeList('网贷', id, this.shopNameList3);
      this.getTypeList('回收中介', id, this.shopNameList4);
    },
    // 获取指定分类的店铺列表
    getTypeList: function getTypeList(name, id, shop) {
      var _this5 = this;
      (0, _request2.default)({
        url: "/get_report_shop_list",
        method: 'post',
        data: {
          record_id: id,
          name: name
        }
      }).then(function (res) {
        // 分类店铺数据
        res.data.map(function (item) {
          // console.log(item, '分类商品')
          shop.push(item);
          _this5.getShopImg(item);
        });
        if (name == '先用后付') {
          _this5.clickShop1(_this5.shopNameList1[0]);
        } else if (name == '租赁') {
          _this5.clickShop2(_this5.shopNameList2[0]);
        } else if (name == '网贷') {
          _this5.clickShop3(_this5.shopNameList3[0]);
        } else if (name == '回收中介') {
          _this5.clickShop4(_this5.shopNameList4[0]);
        }
      });
    },
    getShopImg: function getShopImg(item) {
      (0, _request3.default)({
        url: "/getImageView",
        method: 'get',
        params: {
          path: 'report_shop',
          id: item.id
        }
      }).then(function (res) {
        // 返回的是数据流，转为url，进行展示
        console.log(res);
        // const blob = new Blob([res], { type: 'image/png' })
        // const url = window.URL.createObjectURL(blob)
        // // 给item增加imgurl属性
        // this.$set(item,'imgurl',res)
        // item.url = res.data
        if (res.data == '图片不存在!') {
          item.url = '';
        } else {
          item.url = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    clickShop1: function clickShop1(item) {
      if (item) {
        this.getShopByName(this.queryid, item.name, this.shopList1);
      }
    },
    clickShop2: function clickShop2(item) {
      if (item) {
        this.getShopByName(this.queryid, item.name, this.shopList2);
      }
    },
    clickShop3: function clickShop3(item) {
      if (item) {
        this.getShopByName(this.queryid, item.name, this.shopList3);
      }
    },
    clickShop4: function clickShop4(item) {
      if (item) {
        this.getShopByName(this.queryid, item.name, this.shopList4);
      }
    },
    // 通过店铺名获取店铺数据
    getShopByName: function getShopByName(id, name, shop) {
      (0, _request.default)({
        url: "/system/report/get_report_list/",
        method: 'get',
        params: {
          id: id,
          name: name,
          page: this.listQuery.page,
          page_size: 1000
        }
      }).then(function (res) {
        shop.count = res.data.count;
        shop.results = res.data.results;
      });
    },
    getAllList: function getAllList() {
      var _this6 = this;
      this.isAllLoading = true;
      (0, _request.default)({
        url: "/system/report/get_report_list/",
        method: 'get',
        params: {
          id: this.queryid,
          name: this.formInline.keyword,
          page: this.listQuery.page,
          page_size: this.listQuery.page_size
        }
      }).then(function (res) {
        _this6.isAllLoading = false;
        _this6.allList = res.data;
        _this6.allList.count = res.data.count;
      });
    },
    getTagList: function getTagList() {
      var _this7 = this;
      (0, _request.default)({
        url: "/ReportShopFlag/",
        method: 'get',
        params: {
          page: 1,
          page_size: 100
        }
      }).then(function (res) {
        var results = [];
        _this7.tag_list = res.data.results;
        // res.data.results.forEach(function(item){
        //   results[item.id]=item.name
        // })
        // console.log(results)
        // this.tag_list=results
        // console.log(res)
        // this.getShopList(0)
      });
    },
    // 重置
    resetForm: function resetForm() {
      this.formInline.keyword = '';
      this.getAllList();
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['name', 'perms'])), {}, {
    isAdmin: function isAdmin() {
      // console.log(this.perms)
      return this.perms.includes('admin');
    },
    // 遍历bankList，统计储蓄卡和信用卡的数量
    count: function count() {
      var count = 0;
      this.bankList.map(function (item) {
        if (item.name.includes('储蓄卡')) {
          count++;
        }
      });
      return count;
    },
    count1: function count1() {
      var count1 = 0;
      this.bankList.map(function (item) {
        if (item.name.includes('信用卡')) {
          count1++;
        }
      });
      return count1;
    },
    // 找出hourly_totals数组中的最大值
    max_hourly_totals: function max_hourly_totals() {
      return Math.max.apply(Math, (0, _toConsumableArray2.default)(this.hourly_totals));
    },
    // 找出hourly_totals数组中的最小值
    min_hourly_totals: function min_hourly_totals() {
      return Math.min.apply(Math, (0, _toConsumableArray2.default)(this.hourly_totals));
    },
    // 遍历monthly_money_totals，将每一项的收入，支出，不计收支，其他分别放入新的数组
    sumdata: function sumdata() {
      var _this8 = this;
      var income = [];
      var expenditure = [];
      var nocount = [];
      var other = [];
      this.monthly_money_totals.map(function (item) {
        // 将每一项的收入，支出，不计收支数据，都向下取整，保留2位
        item["收入"] = _this8.formatNum(item["收入"], 2);
        item["支出"] = _this8.formatNum(item["支出"], 2);
        item["不计收支"] = _this8.formatNum(item["不计收支"], 2);
        item["其他"] = _this8.formatNum(item["其他"], 2);
        income.push(item["收入"]);
        expenditure.push(item["支出"]);
        nocount.push(item["不计收支"]);
        other.push(item["其他"]);
      });
      return {
        income: income,
        expenditure: expenditure,
        nocount: nocount,
        other: other
      };
    },
    // 用于图表展示
    incomedata: function incomedata() {
      return {
        legend: {
          data: ['income', 'expenditure', 'nocount', 'other']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '收入',
          type: 'line',
          stack: 'Total',
          data: this.sumdata.income
        }, {
          name: '支出',
          type: 'line',
          stack: 'Total',
          data: this.sumdata.expenditure
        }, {
          name: '不计收支',
          type: 'line',
          stack: 'Total',
          data: this.sumdata.nocount
        }, {
          name: '其他',
          type: 'line',
          stack: 'Total',
          data: this.sumdata.other
        }]
      };
    },
    // 格式化时间为本地时间
    formatTime: function formatTime() {
      return function (time) {
        return new Date(time).toLocaleString();
      };
    }
  }),
  data: function data() {
    return {
      order_from: '',
      dialogFormVisible: false,
      tag_list: [],
      isLoading: true,
      queryid: '',
      //路由获取到的id
      userInfo: {},
      // 用户信息
      type: 0,
      //1支付宝2微信
      time_des: '',
      //时间段
      hourly_totals: [],
      // 日活跃数据
      longest_missing_sequence: 0,
      //最长未使用天数
      missing_percentage: 0,
      // 活跃率
      monthly_totals: [],
      // 月活跃数据
      bankList: {},
      //银行卡列表
      monthly_money_totals: {},
      // 月均收入/支出数据对比
      activeName: 'first',
      //交易商户列表当前选中的tab
      // 交易商户列表TOP15_共6个tab签
      formlist1: [],
      formlist2: [],
      formlist3: [],
      formlist4: [],
      formlist5: [],
      formlist6: [],
      // 不同分类下的商铺列表_共4个分类
      shopNameList1: [],
      shopNameList2: [],
      shopNameList3: [],
      shopNameList4: [],
      // 不同分类下不同商铺的数据
      shopList1: {
        count: 0,
        results: []
      },
      shopList2: {
        count: 0,
        results: []
      },
      shopList3: {
        count: 0,
        results: []
      },
      shopList4: {
        count: 0,
        results: []
      },
      listQuery: {
        page: 1,
        page_size: 10,
        current_page: 1
      },
      isAllLoading: false,
      allList: {
        count: 0,
        results: []
      },
      //全部账单内容
      // 搜索表单
      formInline: {
        keyword: ''
      },
      savedScrollY: 0,
      value: 0,
      // 以下为图表数据
      timedata: {
        tooltip: {},
        xAxis: {
          data: ['1点', '2点', '3点', '4点', '5点', '6点', '7点', '8点', '9点', '10点', '11点', '12点', '13点', '14点', '15点', '16点', '17点', '18点', '19点', '20点', '21点', '22点', '23点', '24点']
        },
        yAxis: {},
        series: [{
          name: '活跃次数',
          type: 'bar',
          data: '',
          colorBy: '#000'
        }]
      },
      monthdata: {
        tooltip: {},
        xAxis: {
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {},
        series: [{
          name: '活跃次数',
          type: 'bar',
          data: '',
          colorBy: '#000',
          showBackground: true,
          //背景色，默认无背景。
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.1)'
          }
        }]
      },
      piedata: {
        xAxis: {
          type: 'category',
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '30%']
        },
        visualMap: {
          type: 'piecewise',
          show: false,
          dimension: 0,
          seriesIndex: 0,
          pieces: [{
            gt: 1,
            lt: 3,
            color: 'rgba(0, 0, 180, 0.4)'
          }, {
            gt: 5,
            lt: 7,
            color: 'rgba(0, 0, 180, 0.4)'
          }]
        },
        series: [{
          type: 'line',
          smooth: 0.6,
          symbol: 'none',
          lineStyle: {
            color: '#5470C6',
            width: 5
          },
          markLine: {
            symbol: ['none', 'none'],
            label: {
              show: false
            },
            data: [{
              xAxis: 1
            }, {
              xAxis: 3
            }, {
              xAxis: 5
            }, {
              xAxis: 7
            }]
          },
          areaStyle: {},
          data: [['2019-10-10', 200], ['2019-10-11', 560], ['2019-10-12', 750], ['2019-10-13', 580], ['2019-10-14', 250], ['2019-10-15', 300], ['2019-10-16', 450], ['2019-10-17', 300], ['2019-10-18', 100]]
        }]
      }
    };
  }
};