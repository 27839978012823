"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    staticStyle: {
      "padding-top": "20px",
      "padding-left": "20px",
      "margin-bottom": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-arrow-left",
      size: "small"
    },
    on: {
      click: _vm.handleBack
    }
  }, [_vm._v("返回上一页")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus",
      size: "small"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("添加" + _vm._s(_vm.cate) + "广告位")])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticStyle: {
      padding: "0 20px"
    },
    attrs: {
      md: 24
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("操作提示：")]), _vm._v("广告位状态设置为上线时，会立即在客户端内上线，无延迟；如有下线广告位需求，请将广告位状态改为下线，暂不支持删除操作。")]), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.adlist,
      border: "",
      stripe: "",
      "header-cell-style": {
        fontWeight: "700",
        backgroundColor: "#eaedf4"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "广告标题"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "url",
      label: "跳转链接",
      width: "280"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "rate",
      label: "展示排序"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "状态",
      prop: "status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.status == 1 ? _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v("上线")]) : _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v("下线")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "content",
      label: "广告详情"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            round: "",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.handleEdit(scope.row);
            }
          }
        }, [_vm._v("修改")])];
      }
    }])
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.title,
      visible: _vm.dialogFormVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleform",
    staticClass: "demo-ruleForm",
    attrs: {
      model: _vm.ruleForm,
      rules: _vm.rules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "广告标题",
      prop: "title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "title", $$v);
      },
      expression: "ruleForm.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "跳转链接",
      prop: "url"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm.url,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "url", $$v);
      },
      expression: "ruleForm.url"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "展示排序",
      prop: "rate"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      min: 0
    },
    model: {
      value: _vm.ruleForm.rate,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "rate", $$v);
      },
      expression: "ruleForm.rate"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态",
      prop: "status"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.ruleForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "status", $$v);
      },
      expression: "ruleForm.status"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("上线")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("下线")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "广告详情",
      prop: "content"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm.content,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "content", $$v);
      },
      expression: "ruleForm.content"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "广告图",
      prop: "ad_img"
    }
  }, [_c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("请上传 jpg/jpeg 格式的图片，暂不支持其他格式")]), _vm._v(" "), _c("input", {
    attrs: {
      type: "file"
    },
    on: {
      change: _vm.handleFileChange
    }
  }), _vm._v(" "), _vm.imageUrl ? _c("div", {
    staticStyle: {
      width: "300px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: _vm.imageUrl,
      alt: "上传图片"
    }
  })]) : _vm._e()]), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("ruleform");
      }
    }
  }, [_vm._v("保存")]), _vm._v(" "), _c("el-button", {
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")])], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;