"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'ShopSelect',
  props: {
    // id: {
    //   type: Number,
    //   required: true
    // }
  },
  data: function data() {
    return {
      shopList: this.$user.shopList,
      value: ''
    };
  },
  created: function created() {
    var _this = this;
    this.$user.getShopList().then(function (resp) {
      _this.shopList = resp;
      _this.value = Number(_this.$user.data.shop);
    });
  },
  methods: {
    updateShop: function updateShop() {
      this.$user.updateShop(this.value);
      // 刷新整个页面
      window.location.reload();
    }
  }
};