"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/deduct_node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  props: {},
  data: function data() {
    return {
      // action:'https://jk.99zuji.com/api/system/order/goods_file_up',
      ruleForm: {
        name: '' //类别名称
      },
      labelWidth: '150px',
      rules: {
        name: [{
          required: true,
          message: '请输入标签名称',
          trigger: 'blur'
        }, {
          min: 2,
          message: '最少2个字符',
          trigger: 'blur'
        }, {
          validator: this.validateName,
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    // 提交表单
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.$emit('add-tag', _objectSpread({}, _this.ruleForm));
        } else {
          alert('提交失败!!');
          return false;
        }
      });
    },
    // 取消重置按钮——done
    resetForm: function resetForm(formName) {
      // 通知父组件取消按钮被点击
      this.$emit('cancel');
      // 清空输入的数据
      this.$refs[formName].resetFields();
    }
  }
};