"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _request = _interopRequireDefault(require("@/utils/request"));
var _default = exports.default = {
  data: function data() {
    return {
      staffData: [],
      // 控制添加form的显示
      dialogFormVisible: false,
      listLoading: false,
      ruleForm: {
        name: '',
        //姓名
        username: '',
        //账号
        password: '',
        //密码
        phone: '' //手机号
      },
      labelWidth: '100px',
      rules: {
        // 姓名
        name: [{
          required: true,
          message: '请输入员工姓名',
          trigger: 'blur'
        }],
        // 账号
        username: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }],
        // 手机号正则
        phone: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          pattern: /^1[3-9]\d{9}$/,
          message: '手机号格式错误',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 6,
          message: '密码至少6位',
          trigger: 'blur'
        }]
      },
      // 控制修改form的显示
      dialogFormVisible1: false,
      ruleForm1: {
        name: '',
        //姓名
        username: '' //账号
        // phone: '',//手机号
      },
      rules1: {
        // 姓名
        name: [{
          required: true,
          message: '请输入员工姓名',
          trigger: 'blur'
        }],
        // 账号
        username: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }],
        // 手机号正则
        phone: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          pattern: /^1[3-9]\d{9}$/,
          message: '手机号格式错误',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 获取员工列表
    getList: function getList() {
      var _this = this;
      // 1.加载中
      this.listLoading = true;
      // 2.发送请求，获取员工列表
      (0, _request.default)({
        url: "/system/user/shop_user_list/",
        method: 'get',
        params: {
          shop_id: this.$user.data.shop
        }
      }).then(function (response) {
        if (response.code === 200) {
          // 3.获取成功
          _this.staffData = response.data;
          // 页面渲染成功后关闭loading
          _this.listLoading = false;
        } else {
          _this.$message({
            message: "\u83B7\u53D6\u5458\u5DE5\u5217\u8868\u5931\u8D25",
            type: 'error'
          });
        }
      }).catch(function (err) {
        console.log(err, '获取员工列表失败');
      });
    },
    // 确认添加员工按钮
    addStaff: function addStaff(formName) {
      var _this2 = this;
      // 1.校验表单
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // 1.加载中
          _this2.listLoading = true;
          // 2.发送请求，添加产品
          (0, _request.default)({
            url: "/system/user/shop_user_set/",
            method: 'post',
            data: {
              name: _this2.ruleForm.name,
              username: _this2.ruleForm.username,
              password: _this2.ruleForm.password,
              phone: _this2.ruleForm.phone
            } // 请求体参数
          }).then(function (response) {
            if (response.code === 200 || 201) {
              // 4.关闭新增产品form
              _this2.dialogFormVisible = false;
              // 3.提示添加成功
              _this2.$message({
                message: "\u6DFB\u52A0\u6210\u529F",
                type: 'success'
              });
              // 关闭loading
              _this2.listLoading = false;
              // 清空数据
              _this2.ruleForm = {
                name: '',
                //姓名
                username: '',
                //账号
                password: '',
                //密码
                phone: '' //手机号
              };
              _this2.getList();
            } else {
              _this2.$message({
                message: "\u6DFB\u52A0\u5931\u8D25",
                type: 'error'
              });
            }
          }).catch(function (err) {
            console.log(err, '新增失败');
          });
        } else {
          _this2.$message({
            message: "\u8BF7\u586B\u5199\u5B8C\u6574\u4FE1\u606F",
            type: 'error'
          });
        }
      });
    },
    // 取消新增员工
    cancel: function cancel() {
      this.dialogFormVisible = false;
      this.ruleForm = {
        name: '',
        //姓名
        username: '',
        //账号
        password: '',
        //密码
        phone: '' //手机号
      };
    },
    checkStaff: function checkStaff(val) {
      this.dialogFormVisible1 = true;
      this.ruleForm1 = val;
    },
    // 修改员工按钮
    editStaff: function editStaff(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // 1.加载中
          _this3.listLoading = true;
          // 2.发送请求，添加产品
          (0, _request.default)({
            url: "/system/user/shop_user_update/",
            method: 'post',
            data: {
              user_id: _this3.ruleForm1.id,
              name: _this3.ruleForm1.name,
              username: _this3.ruleForm1.username,
              phone: _this3.ruleForm1.phone
            } // 请求体参数
          }).then(function (response) {
            if (response.code === 200 || 201) {
              _this3.dialogFormVisible1 = false;
              // 3.提示添加成功
              _this3.$message({
                message: "\u4FEE\u6539\u6210\u529F",
                type: 'success'
              });

              // 关闭loading
              _this3.listLoading = false;
              _this3.getList();
            } else {
              _this3.$message({
                message: "\u4FEE\u6539\u5931\u8D25",
                type: 'error'
              });
            }
          }).catch(function (err) {
            console.log(err, '修改失败');
          });
        } else {
          return false;
        }
      });
    },
    // 取消修改员工
    cancel1: function cancel1() {
      this.dialogFormVisible1 = false;
      // this.$refs['ruleForm1'].resetFields()
    },
    // 删除员工按钮
    deleteStaff: function deleteStaff(value) {
      var _this4 = this;
      console.log('删除员工', value);
      this.$confirm('此操作将永久删除该员工, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // 加删除请求
        (0, _request.default)({
          url: "/system/user/shop_user_delete/",
          method: 'post',
          params: {
            shop_id: _this4.$user.data.shop
          },
          data: {
            user_id: value.id
          } // 请求体参数
        }).then(function (response) {
          // 删除成功提示
          _this4.$message({
            type: 'success',
            message: '删除成功!'
          });
          // 重新获取数据
          _this4.getList();
        }).catch(function () {
          _this4.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};