"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'CopyTag',
  props: {
    content: {
      type: String,
      default: '',
      require: true
    }
  },
  methods: {
    copyIt: function copyIt() {
      var that = this;
      navigator.clipboard.writeText(this.content).then(function () {
        that.$message({
          message: '复制成功',
          type: 'success'
        });
      }).catch(function (error) {
        that.$message({
          message: '无法复制文本: ',
          error: error,
          type: 'warning'
        });
      });
    }
  }
};