"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['Authorization'] = 'Bearer ' + (0, _auth.getToken)();
  }
  var shop = _jsCookie.default.get('currentShop');
  // console.log(shop)
  if (shop) {
    if (!config.params) {
      config.params = {};
    }
    config.params['shop'] = shop;
  }
  return config;
}, function (error) {
  // do something with request error
  // console.log(error) // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  if (res.code >= 200 && res.code < 400) {
    return res;
  }
  if (res.code === 401) {
    if (res.msg.indexOf('No active account') != -1) {
      (0, _elementUi.Message)({
        message: '用户名或密码错误',
        type: 'error',
        duration: 3 * 1000
      });
    } else {
      _elementUi.MessageBox.confirm('认证失败,请重新登陆.', '确认退出', {
        confirmButtonText: '重新登陆',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    }
  } else if (res.code >= 400) {
    (0, _elementUi.Message)({
      message: res.msg || '请求出错',
      type: 'error',
      duration: 3 * 1000
    });
    return Promise.reject(new Error(res.msg || '请求出错'));
  }
}, function (error) {
  // console.log(error,response) // for debug
  (0, _elementUi.Message)({
    message: '服务器错误',
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;