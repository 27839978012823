"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/deduct_node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _RestTable = _interopRequireDefault(require("@/components/RestTable.vue"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _vuex = require("vuex");
var _formatMixin = require("@/utils/formatMixin");
var _encrypt = require("@/utils/encrypt");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  components: {
    RestTable: _RestTable.default,
    Pagination: _index.default
  },
  mixins: [_formatMixin.formatMixin],
  data: function data() {
    return {
      userList: [],
      // 划扣流水
      listQuery: {
        page: 1,
        page_size: 10
      },
      listLoading: false,
      lastExecutedTime: 0,
      // 上次执行的时间
      dialogFormVisible2: false,
      dialogFormVisible4: false,
      msgBtnTxt: '获取验证码',
      msg_code: '',
      pk: 0,
      money: 0,
      userform: {
        id: 0,
        name: '',
        //员工名称
        alipay_name: '',
        //支付宝账号名称
        alipay_login: '',
        //支付宝账号
        money: '',
        //工资
        status: 1,
        //0离职 1在职
        remark: '' //备注       
      },
      // 添加和修改员工表单
      userformtitle: '添加员工',
      //添加和修改员工的弹窗标题
      salaryform: {},
      keyword: '' //关键字
    };
  },
  created: function created() {
    this.getUserList();
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['name', 'perms'])), {}, {
    // isAdmin
    // 如果perms包含 admin 字段 即为管理员，则返回true  否则为false
    isAdmin: function isAdmin() {
      return this.perms.includes('admin');
    },
    is_withdrawal: function is_withdrawal() {
      return this.perms.includes('withdrawal_ok');
    }
  }),
  methods: {
    // 员工列表
    getUserList: function getUserList() {
      var _this = this;
      this.listLoading = true;
      (0, _request.default)({
        url: "/system/user/employees_list/",
        method: 'get',
        params: {
          name: this.keyword
        }
      }).then(function (res) {
        _this.userList = res.data;
        _this.listLoading = false;
      });
    },
    getCode: function getCode() {
      var _this2 = this;
      //  1.验证手机号
      // 成功则发验证码
      // 参数加密
      var timestamp = Date.now().toString();
      var safe = (0, _encrypt.encrypt)('13017111117' + "&" + timestamp);
      // 发请求
      (0, _request.default)({
        url: 'anon/send_msg',
        method: 'post',
        data: {
          mobile: '13017111117',
          timestamp: timestamp,
          safe: safe
        }
      }).then(function (res) {
        _this2.$message.success('验证码发送成功');
        _this2.cycleMsg(60);
      });
    },
    // 倒计时
    cycleMsg: function cycleMsg(currentSecond) {
      var _this3 = this;
      if (currentSecond > 0) {
        this.msgBtnTxt = "".concat(currentSecond, "\u79D2");
        setTimeout(function () {
          return _this3.cycleMsg(currentSecond - 1);
        }, 1000);
      } else {
        this.msgBtnTxt = '获取验证码';
      }
    },
    // 发放工资按钮
    withMoney: function withMoney(value) {
      var _this4 = this;
      // console.log(value)
      var txt = value.is_pay == 1 ? '已发放' : '未发放';
      this.$confirm("\u672C\u6708\u5DE5\u8D44".concat(txt, "\uFF0C\u662F\u5426\u7EE7\u7EED?"), '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.salaryform = value;
        _this4.dialogFormVisible2 = true;
      });
    },
    // 工资发放——先校验验证码，再调发放接口
    clossPop2: function clossPop2() {
      var _this5 = this;
      var timestamp = Date.now().toString();
      var safe = (0, _encrypt.encrypt)('13017111117' + "&" + this.msg_code + "&" + timestamp);
      // 校验手机号和验证码
      (0, _request.default)({
        url: 'anon/check_msg',
        method: 'post',
        data: {
          mobile: '13017111117',
          msg_code: this.msg_code,
          timestamp: timestamp,
          safe: safe
        }
      }).then(function (res) {
        if (res.code == 200) {
          _this5.appove1();
        }
      });
    },
    appove1: function appove1() {
      var _this6 = this;
      if (this.is_withdrawal || this.isAdmin) {
        var currentTime = Date.now();
        if (currentTime - this.lastExecutedTime > 2000) {
          // 校验成功自动发工资
          (0, _request.default)({
            url: '/system/user/employees_put/',
            method: 'post',
            data: {
              id: this.salaryform.id
            }
          }).then(function (res) {
            if (res.code == 200) {
              _this6.dialogFormVisible2 = false;
              _this6.$message.success('发放成功');
              _this6.getUserList();
            } else {
              _this6.$message.error(res.msg);
            }
          });
        } else {
          this.$message.error('请勿频繁操作');
          this.lastExecutedTime = currentTime;
        }
      } else {
        this.$message({
          message: '您没有操作权限',
          type: 'warning'
        });
        this.dialogFormVisible2 = false;
      }
    },
    // 确认添加和修改员工
    clossPop4: function clossPop4() {
      var _this7 = this;
      if (this.userformtitle == '添加员工') {
        (0, _request.default)({
          url: "/system/user/employees_set/",
          method: 'post',
          data: _objectSpread({}, this.userform)
        }).then(function (res) {
          if (res.code == 200) {
            _this7.dialogFormVisible4 = false;
            _this7.$message.success('添加成功');
            _this7.getUserList();
          }
        });
      } else {
        (0, _request.default)({
          url: "/system/user/employees_set/",
          method: 'post',
          data: _objectSpread({
            id: this.userform.id
          }, this.userform)
        }).then(function (res) {
          if (res.code == 200) {
            _this7.dialogFormVisible4 = false;
            _this7.$message.success('修改成功');
            _this7.getUserList();
          }
        });
      }
    },
    // 添加和修改员工
    setUserAlipay: function setUserAlipay(item) {
      if (item) {
        this.userformtitle = '修改员工';
        this.userform = item;
        this.dialogFormVisible4 = true;
      } else {
        this.userformtitle = '添加员工';
        this.dialogFormVisible4 = true;
      }
    },
    // 清空表单
    clearUser: function clearUser() {
      // 清空数据
      this.userform = {
        id: 0,
        name: '',
        //员工名称
        alipay_name: '',
        //支付宝账号名称
        alipay_login: '',
        //支付宝账号
        money: '',
        //工资
        status: 1,
        //0离职 1在职
        remark: '' //备注       
      };
      this.userformtitle = '';
    },
    reload: function reload() {
      this.keyword = '';
      this.getUserList();
    }
  }
};