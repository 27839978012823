"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/deduct_node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _fileImage = _interopRequireDefault(require("@/components/fileImage.vue"));
var _copyTag = _interopRequireDefault(require("@/components/Widget/copyTag.vue"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _vuex = require("vuex");
var _formatMixin = require("@/utils/formatMixin");
var _qrcode = _interopRequireDefault(require("qrcode"));
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  components: {
    CopyTag: _copyTag.default,
    FileImage: _fileImage.default,
    Pagination: _index.default
  },
  mixins: [_formatMixin.formatMixin],
  data: function data() {
    return {
      document: {},
      // 订单数据
      attachments: [],
      listLoading: true,
      src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      dialogVisibleEdit: false,
      //控制修改订单form
      dialogVisibleAccount: false,
      //控制结算订单
      checkOrderMoney: 0,
      //结算金额
      // 修改订单form
      orderform: {
        is_finish: 1
      },
      userInfo: {},
      // 下单用户信息
      orderList: {},
      //当前订单对应的流水
      listQuery: {
        page: 1,
        page_size: 10
      },
      listQuery2: {
        page: 1,
        page_size: 10
      },
      periodList: {
        count: 0
      },
      //划扣分期
      dialogVisibleUser: false,
      //修改用户信息的form
      // 收集修改form的数据
      userform: {
        name: '',
        card_code: ''
      },
      multipleSelection: []
    };
  },
  created: function created() {
    this.pk = this.$route.query.id;
    this.listQuery2.page = this.$route.query.page;
    this.getList();
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['name', 'perms'])), {}, {
    // isAdmin
    // 如果perms包含 admin 字段 即为管理员，则返回true  否则为false
    // 超级管理员
    isAdmin: function isAdmin() {
      return this.perms.includes('admin');
    },
    // 店铺管理员
    is_manager: function is_manager() {
      return this.perms.includes('good_add');
    },
    is_order_close: function is_order_close() {
      return this.perms.includes('order_close');
    },
    is_order_settle: function is_order_settle() {
      return this.perms.includes('order_settle');
    }
  }),
  methods: {
    handleSelectionChange: function handleSelectionChange(e) {
      // console.log(e)
      this.multipleSelection = e;
    },
    cancelRendlist: function cancelRendlist() {
      var _this = this;
      var selection = this.multipleSelection;
      if (selection.length <= 0) {
        this.$message.error('请至少勾选一个扣款周期');
        return;
      }
      var ids = '';
      var _iterator = _createForOfIteratorHelper(selection),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          console.log(item);
          if (item.is_cancel == false && item.status == false) {
            ids = ids + ',' + item.id;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      if (ids == '') {
        this.$message.error('无需取消');
        return;
      }
      this.listLoading = true;
      (0, _request.default)({
        url: "/system/order/cancel_hk_list/",
        method: 'put',
        data: {
          order_id: this.pk,
          ids: ids
        }
      }).then(function (response) {
        if (response.data) {
          _this.getPeriodList();
          // this.orderList = response.data
          // this.orderList = this.orderList.reverse()
        }
        _this.listLoading = false;
      });
      // console.log(ids)
    },
    // 根据id获取订单数据
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _request.default)({
        url: "/Order/".concat(this.pk, "/"),
        method: 'get',
        params: {
          shop: this.$user.data.shop
        }
      }).then(function (response) {
        if (response.data) {
          _this2.document = response.data;
          _this2.checkOrderMoney = response.data.money;
          _this2.getUserInfo();
          _this2.getOrderList();
          _this2.getPeriodList();
        }
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    // 页头--返回上级页面
    goBack: function goBack() {
      this.$router.push({
        name: 'orderList',
        query: {
          page: this.listQuery2.page
        }
      });

      //   this.$router.go(-1)
    },
    // 修改订单审核状态
    approveChange: function approveChange(value) {
      // console.log(value)
      this.orderform.is_approve = value;
      // this.document.is_approve = value
    },
    // 结束订单
    onSubmitOrder: function onSubmitOrder() {
      var _this3 = this;
      this.$confirm('确定结束该订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this3.document.is_esign_contract == 1 && _this3.document.is_alipay_contract == 1) {
          (0, _request.default)({
            url: "/system/order/order_cenel/",
            method: 'put',
            data: {
              order_id: _this3.pk
            }
          }).then(function (response) {
            if (response.data) {
              _this3.$message({
                message: '订单结束成功',
                type: 'success'
              });
              setTimeout(function () {
                (0, _request.default)({
                  url: "/system/order/order_cenel1/",
                  method: 'put',
                  data: {
                    order_id: _this3.pk
                  }
                }).then(function (response) {
                  window.location.reload();
                });
              }, 500);
              _this3.getList();
              // window.location.reload(); // 刷新页面
            }
          });
        } else {
          (0, _request.default)({
            url: "/system/order/order_cenel1/",
            method: 'put',
            data: {
              order_id: _this3.pk
            }
          }).then(function (response) {
            window.location.reload();
          });
        }
        // 发请求提交数据给后台
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    // 重新生成订单号
    refreshOrderNo: function refreshOrderNo() {
      var _this4 = this;
      // 发请求提交数据给后台
      (0, _request.default)({
        url: "/system/order/changeOrderNo/",
        method: 'put',
        data: {
          order_id: this.pk
        }
      }).then(function (response) {
        if (response.data) {
          _this4.$message({
            message: '订单号已重新生成',
            type: 'success'
          });
          _this4.getList();
        }
      });
    },
    // 下载租赁合同_管理员_商家_仅已签署的合同
    upload: function upload() {
      if (this.is_manager || this.isAdmin) {
        window.open(this.document.down_url);
      } else {
        this.$message({
          message: '请联系店铺管理员下载合同',
          type: 'warning'
        });
      }
    },
    // 获取订单流水
    getOrderList: function getOrderList() {
      var _this5 = this;
      this.listLoading = true;
      (0, _request.default)({
        url: "/system/order/getUserRecord/",
        method: 'put',
        data: {
          order_id: this.pk
        }
      }).then(function (response) {
        if (response.data) {
          _this5.orderList = response.data;
          _this5.orderList = _this5.orderList.reverse();
        }
        _this5.listLoading = false;
      });
    },
    // 获取划扣分期
    getPeriodList: function getPeriodList() {
      var _this6 = this;
      this.listLoading = true;
      (0, _request.default)({
        url: "/OrderHk/",
        method: 'get',
        params: Object.assign({
          shop: this.$user.data.shop
        }, {
          order: this.pk
        }, this.listQuery)
      }).then(function (response) {
        if (response.data) {
          _this6.periodList = response.data;
          // this.periodList = this.periodList.reverse()
        }
        _this6.listLoading = false;
      });
    },
    // 获取下单用户的信息
    getUserInfo: function getUserInfo() {
      var _this7 = this;
      (0, _request.default)({
        url: "/system/customer/getInfo/",
        method: 'get',
        params: {
          shop: this.$user.data.shop,
          customer_id: this.document.alipay_user
        }
      }).then(function (response) {
        if (response.data) {
          _this7.userInfo = response.data;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    updateUserInfo: function updateUserInfo() {
      var _this8 = this;
      if (this.userform.name == '' || this.userform.card_code == '') {
        this.$message({
          message: '请填写完整信息',
          type: 'warning'
        });
        return;
      }
      if (this.userform.card_code.length != 18) {
        this.$message({
          message: '身份证号位数错误',
          type: 'warning'
        });
        return;
      }
      var chineseIDCardNumberRegex = /^\d{17}[\dX]$/;
      if (!chineseIDCardNumberRegex.test(this.userform.card_code)) {
        this.$message({
          message: '身份证号格式错误，字母请大写',
          type: 'warning'
        });
        return;
      }
      (0, _request.default)({
        url: "/system/customer/updateCustomerInfo/",
        method: 'put',
        // params: { shop: this.$user.data.shop, customer_id: this.document.alipay_user },
        data: {
          customer_id: this.document.alipay_user,
          real_name: this.userform.name,
          card_code: this.userform.card_code
        }
      }).then(function (res) {
        if (res.data) {
          _this8.$message({
            message: '修改成功',
            type: 'success'
          });
          _this8.dialogVisibleUser = false;
          // 重新获取用户信息
          _this8.getUserInfo();
          // 清空数据
          _this8.userform.name = '';
          _this8.userform.card_code = '';
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    checkHK: function checkHK() {
      var _this9 = this;
      //验证订单签约情况
      (0, _request.default)({
        url: "/signOrder/checkHK",
        method: 'get',
        params: {
          orderNo: this.document.orderNo
        }
        // params: { shop: this.$user.data.shop, customer_id: this.document.alipay_user },
        // data: {}
      }).then(function (res) {
        console.log(res);
        if (res.code == 200) {
          _this9.getPeriodList();
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    checkSign: function checkSign() {
      var _this10 = this;
      //验证订单签约情况
      (0, _request.default)({
        url: "/signOrder/checkStatus",
        method: 'get',
        params: {
          orderNo: this.document.orderNo
        }
        // params: { shop: this.$user.data.shop, customer_id: this.document.alipay_user },
        // data: {}
      }).then(function (res) {
        console.log(res);
        if (res.code == 200) {
          _this10.getList();
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 结算订单
    checkOrder: function checkOrder() {
      this.dialogVisibleAccount = true;
      console.log(this.pk + '——结清订单');
    },
    //生成订单结算二维码
    onSubmitAccount: function onSubmitAccount() {
      var _this11 = this;
      (0, _request.default)({
        url: "/system/order/order_settle/",
        method: 'put',
        data: {
          checkOrderMoney: this.checkOrderMoney,
          order_id: this.pk
        }
      }).then(function (response) {
        _this11.dialogVisibleAccount = false;
        _this11.$message({
          dangerouslyUseHTMLString: true,
          message: '<img style="width:200px;height:200px" src="' + response.data + '"></img>',
          duration: 0,
          showClose: true
        });
      });
    },
    //生成订单服务费用二维码
    onSubmitPayEmw: function onSubmitPayEmw() {
      var _this12 = this;
      console.log(this.pk);
      // this.$dialog({
      //   title: '订单结束成功',
      // })
      (0, _request.default)({
        url: "/system/order/order_pay_ewm/",
        method: 'put',
        data: {
          order_id: this.pk
        }
      }).then(function (response) {
        console.log(response.data);
        _this12.$message({
          dangerouslyUseHTMLString: true,
          message: '<img style="width:200px;height:200px" src="' + response.data + '"></img>',
          duration: 0,
          showClose: true
        });
      });
    },
    orderSignContract: function orderSignContract() {
      var _this13 = this;
      (0, _request.default)({
        url: "/three/order/sign_contract",
        method: 'post',
        data: {
          orderNo: this.document.orderNo
        }
      }).then(function (response) {
        console.log(response);
        _qrcode.default.toDataURL(response.url).then(function (qrImg) {
          console.log(qrImg);
          _this13.$message({
            dangerouslyUseHTMLString: true,
            message: '<img style="width:200px;height:200px" src="' + qrImg + '"></img>',
            duration: 0,
            showClose: true
          });
        }).catch(function (error) {
          console.error(error);
        });
      });
    },
    orderSignOrder: function orderSignOrder() {
      var _this14 = this;
      (0, _request.default)({
        url: "/three/order/sign_order",
        method: 'get',
        params: {
          orderNo: this.document.orderNo
        }
      }).then(function (response) {
        console.log(response);
        _qrcode.default.toDataURL(response.url).then(function (qrImg) {
          console.log(qrImg);
          _this14.$message({
            dangerouslyUseHTMLString: true,
            message: '<img style="width:500px;height:500px" src="' + qrImg + '"></img>',
            duration: 0,
            showClose: true
          });
        }).catch(function (error) {
          console.error(error);
        });
      });
    },
    returnPay: function returnPay(rendNo) {
      var _this15 = this;
      (0, _request.default)({
        url: "/system/order/order_pay_return_new/",
        method: 'put',
        data: {
          rendNo: rendNo
        }
      }).then(function (response) {
        console.log(response);
        if (response.code == 200) {
          _this15.$message({
            message: response.data,
            type: 'success'
          });
          _this15.getOrderList();
        } else {
          _this15.$message({
            message: response.data,
            type: 'warning'
          });
        }
      });
    }
  }
};