"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.encrypt = encrypt;
require("core-js/modules/es6.regexp.to-string");
var _core = _interopRequireDefault(require("crypto-js/core"));
var _aes = _interopRequireDefault(require("crypto-js/aes"));
var publicKeyString = "AAAAAAAAAAAAAAAA";
var iv = _core.default.enc.Utf8.parse('BBBBBBBBBBBBBBBB');
function encrypt(plaintext) {
  var key = _core.default.enc.Utf8.parse(publicKeyString);
  var encrypted = _aes.default.encrypt(plaintext, key, {
    iv: iv,
    mode: _core.default.mode.CBC
  });
  return encrypted.toString();
}