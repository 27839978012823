"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-table", {
    attrs: {
      data: _vm.tabledata1,
      stripe: "",
      border: "",
      "header-cell-style": {
        color: "#fff",
        fontWeight: "700",
        backgroundColor: "#409eff"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_time",
      label: "交易时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "money",
      label: "金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                " + _vm._s(scope.row.money) + "元\n            ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_des",
      label: "交易描述"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_from",
      label: "交易方",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_type",
      label: "交易类型",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "re_or_ex",
      label: "账单类型",
      width: "width"
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;