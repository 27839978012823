"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/deduct_node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  props: {
    pk: Number,
    // 类别id
    isEdit: Boolean //编辑还是新增
  },
  data: function data() {
    return {
      // action:'https://jk.99zuji.com/api/system/order/goods_file_up',
      ruleForm: {
        title: '',
        //类别名称
        status: 1,
        //状态
        rate: 1 //排序
      },
      labelWidth: '150px',
      rules: {
        title: [{
          required: true,
          message: '请输入类别名称',
          trigger: 'blur'
        }, {
          min: 2,
          message: '最少2个字符',
          trigger: 'blur'
        }, {
          validator: this.validateName,
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    // 当是修改产品时才会请求数据
    // 新增不请求
    // console.log(this.pk)
    if (this.pk) {
      this.getList();
    }
  },
  methods: {
    // 提交表单
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this.isEdit) {
            // 修改产品的逻辑（自定义事件名，小写或-连接，不能小驼峰命名）
            _this.$emit('edit-good', _objectSpread(_objectSpread({}, _this.ruleForm), {}, {
              status: _this.ruleForm.status == 1 ? true : false
            }));
          } else {
            // 添加产品的逻辑
            // 整理参数，将shop_name属性排除，上架状态传递中文
            _this.$emit('add-good', _objectSpread({}, _this.ruleForm));
          }
        } else {
          alert('提交失败!!');
          return false;
        }
      });
    },
    // 取消重置按钮——done
    resetForm: function resetForm(formName) {
      if (this.isEdit) {
        // 通知父组件取消按钮被点击
        this.$emit('cancel');
        // 清空输入的数据
        this.$refs[formName].resetFields();
        // 重新获取数据
        this.getList();
      } else {
        // 通知父组件取消按钮被点击
        this.$emit('cancel');
        // 清空输入的数据
        this.$refs[formName].resetFields();
      }
    },
    // 根据id获取产品详情——done
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _request.default)({
        url: "/AdCate/".concat(this.pk, "/"),
        method: 'get',
        params: {
          shop: this.$user.data.shop
        }
      }).then(function (response) {
        if (response.data) {
          // 保存数据
          // console.log(response.data)
          _this2.ruleForm = response.data;
          _this2.ruleForm.status = response.data.status ? 1 : 0;
          // console.log(this.ruleForm)
        }
        _this2.listLoading = false;
      });
    }
  }
};