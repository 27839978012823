"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRole = createRole;
exports.deleteRole = deleteRole;
exports.getRoleAll = getRoleAll;
exports.getRoutes = getRoutes;
exports.updateRole = updateRole;
var _request = _interopRequireDefault(require("@/utils/request"));
function getRoutes() {
  return (0, _request.default)({
    url: '/system/permission/',
    method: 'get'
  });
}
function getRoleAll() {
  return (0, _request.default)({
    url: '/system/role/',
    method: 'get'
  });
}
function createRole(data) {
  return (0, _request.default)({
    url: '/system/role/',
    method: 'post',
    data: data
  });
}
function updateRole(id, data) {
  return (0, _request.default)({
    url: "/system/role/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteRole(id) {
  return (0, _request.default)({
    url: "/system/role/".concat(id, "/"),
    method: 'delete'
  });
}