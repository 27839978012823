"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/deduct_node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _RestTable = _interopRequireDefault(require("@/components/RestTable.vue"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _vuex = require("vuex");
var _formatMixin = require("@/utils/formatMixin");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  components: {
    RestTable: _RestTable.default,
    Pagination: _index.default
  },
  mixins: [_formatMixin.formatMixin],
  data: function data() {
    return {
      moneyList: {
        count: 0
      },
      // 划扣流水
      listQuery: {
        page: 1,
        page_size: 10
      },
      listLoading: false,
      value1: ''
    };
  },
  created: function created() {
    this.getMoneyList();
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['name', 'perms'])), {}, {
    // isAdmin
    // 如果perms包含 admin 字段 即为管理员，则返回true  否则为false
    isAdmin: function isAdmin() {
      return this.perms.includes('admin');
    },
    is_withdrawal: function is_withdrawal() {
      return this.perms.includes('withdrawal_ok');
    },
    start_time: function start_time() {
      return this.value1[0] ? this.formatDate(this.value1[0]) : this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    },
    end_time: function end_time() {
      return this.value1[1] ? this.formatDate(this.value1[1]) : this.formatDate(new Date());
    }
  }),
  methods: {
    // 发放记录
    getMoneyList: function getMoneyList() {
      var _this = this;
      // this.listLoading = true
      (0, _request.default)({
        url: "/system/user/employees_log/",
        method: 'post',
        data: {
          page: this.listQuery.page,
          page_size: this.listQuery.page_size,
          end_time: this.end_time,
          start_time: this.start_time
        }
      }).then(function (response) {
        if (response.data) {
          _this.moneyList = response.data.data.results;
          _this.moneyList.count = response.data.data.count;
        }
        _this.listLoading = false;
      });
    },
    // 重置数据
    resetData: function resetData() {
      this.value1 = '';
      this.getMoneyList();
    },
    // 格式化日期
    formatDate: function formatDate(date) {
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
      var day = String(date.getDate()).padStart(2, '0');
      var hour = String(date.getHours()).padStart(2, '0');
      var minute = String(date.getMinutes()).padStart(2, '0');
      var second = String(date.getSeconds()).padStart(2, '0');
      return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(minute, ":").concat(second);
    }
  }
};