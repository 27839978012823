"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    staticStyle: {
      padding: "10px"
    },
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      md: 24
    }
  }, [!_vm.document["is_wfl"] ? _c("el-button", {
    attrs: {
      type: "success",
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.submitReport("approve");
      }
    }
  }, [_vm._v("审核通过\n      ")]) : _vm._e(), _vm._v(" "), !_vm.document["is_wfl"] ? _c("el-button", {
    attrs: {
      type: "warning",
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.submitReport("reject");
      }
    }
  }, [_vm._v("审核拒绝\n      ")]) : _vm._e(), _vm._v(" "), _c("el-button", {
    staticClass: "filter-item",
    staticStyle: {
      float: "right"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "small"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v("刷新")])], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-card", {
    staticStyle: {
      padding: "10px 10px 30px 10px",
      margin: "0 10px"
    }
  }, [_c("el-header", [_c("h3", [_vm._v("\n          注册信息\n          "), !_vm.document["is_wfl"] ? _c("el-tag", {
    attrs: {
      type: "info"
    }
  }, [_vm._v("未审核")]) : _vm._e(), _vm._v(" "), _vm.document["is_approve"] ? _c("el-tag", {
    attrs: {
      type: "success"
    }
  }, [_vm._v("审核通过")]) : _vm._e(), _vm._v(" "), _vm.document["is_reject"] ? _c("el-tag", {
    attrs: {
      type: "waring"
    }
  }, [_vm._v("审核拒绝")]) : _vm._e()], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      md: 8
    }
  }, [_c("div", {
    staticStyle: {
      margin: "0 20px"
    }
  }, [_c("el-descriptions", {
    staticClass: "margin-top",
    staticStyle: {
      "font-size": "16px"
    },
    attrs: {
      column: 1,
      border: "",
      title: "商家注册申请表"
    }
  }, [_c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-user"
  }), _vm._v("\n                账户名\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.username) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-mobile-phone"
  }), _vm._v("\n                手机号\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.mobile) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-location-outline"
  }), _vm._v("\n                真实姓名\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.real_name) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-bank-card"
  }), _vm._v("\n                身份证\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.card_code) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-office-building"
  }), _vm._v("\n                支付宝账号\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.alipay) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-office-building"
  }), _vm._v("\n                公司名称\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.company_name) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-location-outline"
  }), _vm._v("\n                公司地址\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.company_address) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-message"
  }), _vm._v("\n                公司邮箱\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.company_email) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-picture-outline"
  }), _vm._v("\n                统一社会信用代码\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.company_scc) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-setting"
  }), _vm._v("\n                E签宝合同章号\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.sealId) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-goods"
  }), _vm._v("\n                产品名字\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.product_name) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-shopping-cart-full"
  }), _vm._v("\n                商城链接\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.product_address) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-shopping-cart-full"
  }), _vm._v("\n                客服电话\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.kefu_mobile) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-place"
  }), _vm._v("\n                注册ip\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.reg_ip) + "\n            ")], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-setting"
  }), _vm._v("\n                提交时间\n              ")]), _vm._v("\n              " + _vm._s(_vm.document.create_time) + "\n            ")], 2)], 1)], 1)]), _vm._v(" "), _c("el-col", {
    staticStyle: {
      "padding-top": "40px"
    },
    attrs: {
      md: 12,
      offset: 1
    }
  }, [_c("h3", [_vm._v("公司营业执照")]), _vm._v(" "), _c("div", _vm._l(_vm.attachments.filter(function (attachment2) {
    return attachment2["flag"] === "company_scc_picture";
  }), function (attachment) {
    return _c("div", {
      key: attachment.id
    }, [_c("FileImage", {
      key: "file".concat(attachment["file"]),
      attrs: {
        id: attachment["file"]
      }
    })], 1);
  }), 0), _vm._v(" "), _c("h3", [_vm._v("公司运营照片")]), _vm._v(" "), _c("div", _vm._l(_vm.attachments.filter(function (attachment2) {
    return attachment2["flag"] === "company_picture";
  }), function (attachment) {
    return _c("div", {
      key: attachment.id
    }, [_c("FileImage", {
      key: "file".concat(attachment["file"]),
      attrs: {
        id: attachment["file"]
      }
    })], 1);
  }), 0), _vm._v(" "), _c("h3", [_vm._v("身份证正反面")]), _vm._v(" "), _c("div", _vm._l(_vm.attachments.filter(function (attachment2) {
    return attachment2["flag"] === "person_picture";
  }), function (attachment) {
    return _c("div", {
      key: attachment.id
    }, [_c("FileImage", {
      key: "file".concat(attachment["file"]),
      attrs: {
        id: attachment["file"]
      }
    })], 1);
  }), 0)])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;