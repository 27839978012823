"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/deduct_node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _RestTable = _interopRequireDefault(require("@/components/RestTable.vue"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _vuex = require("vuex");
var _formatMixin = require("@/utils/formatMixin");
var _encrypt = require("@/utils/encrypt");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  components: {
    RestTable: _RestTable.default,
    Pagination: _index.default
  },
  mixins: [_formatMixin.formatMixin],
  data: function data() {
    return {
      moneyList: {
        count: 0
      },
      // 划扣流水
      listQuery: {
        page: 1,
        page_size: 10
      },
      listLoading: false,
      lastExecutedTime: 0,
      // 上次执行的时间
      user_money: 0,
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      msgBtnTxt: '获取验证码',
      msg_code: '',
      pk: 0,
      money: 0,
      get_money: 0,
      alipay_name: '',
      alipay_login: ''
    };
  },
  created: function created() {
    this.getMoneyList();
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['name', 'perms'])), {}, {
    // isAdmin
    // 如果perms包含 admin 字段 即为管理员，则返回true  否则为false
    isAdmin: function isAdmin() {
      return this.perms.includes('admin');
    },
    is_withdrawal: function is_withdrawal() {
      return this.perms.includes('withdrawal_ok');
    }
  }),
  methods: {
    getCode: function getCode() {
      var _this = this;
      //  1.验证手机号
      // 成功则发验证码
      // 参数加密
      var timestamp = Date.now().toString();
      var safe = (0, _encrypt.encrypt)('13017111117' + "&" + timestamp);
      // 发请求
      (0, _request.default)({
        url: 'anon/send_msg',
        method: 'post',
        data: {
          mobile: '13017111117',
          timestamp: timestamp,
          safe: safe
        }
      }).then(function (res) {
        _this.$message.success('验证码发送成功');
        _this.cycleMsg(60);
      });
    },
    // 倒计时
    cycleMsg: function cycleMsg(currentSecond) {
      var _this2 = this;
      if (currentSecond > 0) {
        this.msgBtnTxt = "".concat(currentSecond, "\u79D2");
        setTimeout(function () {
          return _this2.cycleMsg(currentSecond - 1);
        }, 1000);
      } else {
        this.msgBtnTxt = '获取验证码';
      }
    },
    // 获取提现明细——done
    getMoneyList: function getMoneyList() {
      var _this3 = this;
      this.listLoading = true;
      // 王工封装
      // type 2 为支付宝账号流水
      (0, _request.default)({
        url: "/system/order/getUserRecord/",
        method: 'put',
        data: {
          shop_id: this.$user.data.shop,
          type: 2,
          remark: '余额提现',
          page: this.listQuery.page,
          page_size: this.listQuery.page_size,
          is_pay: -1
        }
      }).then(function (response) {
        if (response.data) {
          _this3.moneyList = response.data.data;
          _this3.user_money = response.data.data.user_money;
        }
        _this3.listLoading = false;
      });
    },
    // 确认打款，加限制——done
    approve: function approve(value) {
      this.dialogFormVisible = true;
      this.pk = value.pk;
    },
    withMoney: function withMoney(value) {
      var _this4 = this;
      this.$confirm('确认提现金额' + value.money + '元到平台余额?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // 发请求提交数据给后端
        (0, _request.default)({
          url: "/system/user/withMoney/",
          method: 'put',
          data: {
            record_id: value.pk
          }
        }).then(function (response) {
          if (response.code == 200) {
            _this4.$message({
              message: '提现成功',
              type: 'success'
            });
            _this4.getMoneyList(); // 刷新列表
          }
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消提现'
        });
      });
    },
    clossPop: function clossPop() {
      var _this5 = this;
      var timestamp = Date.now().toString();
      var safe = (0, _encrypt.encrypt)('13017111117' + "&" + this.msg_code + "&" + timestamp);
      // 发请求
      (0, _request.default)({
        url: 'anon/check_msg',
        method: 'post',
        data: {
          mobile: '13017111117',
          msg_code: this.msg_code,
          timestamp: timestamp,
          safe: safe
        }
      }).then(function (res) {
        if (res.code == 200) {
          _this5.dialogFormVisible = false;
          _this5.approve1();
        }
      });
    },
    clossPop2: function clossPop2() {
      var _this6 = this;
      var timestamp = Date.now().toString();
      var safe = (0, _encrypt.encrypt)('13017111117' + "&" + this.msg_code + "&" + timestamp);
      // 发请求
      (0, _request.default)({
        url: 'anon/check_msg',
        method: 'post',
        data: {
          mobile: '13017111117',
          msg_code: this.msg_code,
          timestamp: timestamp,
          safe: safe
        }
      }).then(function (res) {
        if (res.code == 200) {
          _this6.dialogFormVisible2 = false;
          _this6.approve2();
        }
      });
    },
    clossPop3: function clossPop3() {
      var _this7 = this;
      (0, _request.default)({
        url: "/system/user/WithdrawalApplication/",
        method: 'put',
        data: {
          money: this.get_money,
          shop_id: this.$user.data.shop
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this7.getMoneyList(); // 刷新列表
          _this7.dialogFormVisible3 = false;
          _this7.lastExecutedTime = currentTime;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    clossPop4: function clossPop4() {
      var _this8 = this;
      var timestamp = Date.now().toString();
      var safe = (0, _encrypt.encrypt)('13017111117' + "&" + this.msg_code + "&" + timestamp);
      // 发请求
      (0, _request.default)({
        url: 'anon/check_msg',
        method: 'post',
        data: {
          mobile: '13017111117',
          msg_code: this.msg_code,
          timestamp: timestamp,
          safe: safe
        }
      }).then(function (res) {
        if (res.code == 200) {
          _this8.dialogFormVisible4 = false;
          _this8.approve3();
        }
      });
    },
    approve3: function approve3() {
      var _this9 = this;
      (0, _request.default)({
        url: "/system/user/setUserAlipay/",
        method: 'put',
        data: {
          alipay_name: this.alipay_name,
          alipay_login: this.alipay_login,
          shop_id: this.$user.data.shop
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this9.getMoneyList(); // 刷新列表
          _this9.dialogFormVisible4 = false;
          _this9.lastExecutedTime = currentTime;
          _this9.$message({
            message: '修改成功',
            type: 'success'
          });
        } else {
          _this9.$message.error(response.data);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    approve2: function approve2() {
      var _this10 = this;
      if (this.is_withdrawal || this.isAdmin) {
        var _currentTime = Date.now();
        if (_currentTime - this.lastExecutedTime > 2000) {
          // 发请求提交数据给后端
          (0, _request.default)({
            url: "/system/user/addWithdrawal/",
            method: 'put',
            data: {
              shop_id: this.$user.data.shop,
              money: this.money
            }
          }).then(function (response) {
            if (response.code == 200) {
              _this10.getMoneyList(); // 刷新列表
              _this10.lastExecutedTime = _currentTime;
            }
          }).catch(function (error) {
            console.log(error);
          });
        } else {
          this.$message.error('请勿频繁操作');
          this.lastExecutedTime = _currentTime;
        }
      } else {
        this.$message({
          message: '您没有操作权限',
          type: 'warning'
        });
      }
    },
    approve1: function approve1() {
      var _this11 = this;
      if (this.is_withdrawal || this.isAdmin) {
        var _currentTime2 = Date.now();
        if (_currentTime2 - this.lastExecutedTime > 2000) {
          // 发请求提交数据给后端
          (0, _request.default)({
            url: "/system/user/withdrawalOk/",
            method: 'put',
            data: {
              id: this.pk
            }
          }).then(function (response) {
            if (response.code == 200) {
              _this11.getMoneyList(); // 刷新列表
              _this11.lastExecutedTime = _currentTime2;
            }
          }).catch(function (error) {
            console.log(error);
          });
        } else {
          this.$message.error('请勿频繁操作');
          this.lastExecutedTime = _currentTime2;
        }
      } else {
        this.$message({
          message: '您没有操作权限',
          type: 'warning'
        });
      }
    },
    deleteRow: function deleteRow(value) {
      var _this12 = this;
      this.$confirm('确认删除当前提现记录?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // 发请求提交数据给后端
        (0, _request.default)({
          url: "/system/order/userRecordDel/",
          method: 'delete',
          data: {
            record_id: value.pk
          }
        }).then(function (response) {
          if (response.code == 200) {
            _this12.$message({
              message: '删除成功',
              type: 'success'
            });
            _this12.getMoneyList(); // 刷新列表
          }
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(function () {
        _this12.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addMoney: function addMoney() {
      this.dialogFormVisible2 = true;
    },
    getMoney: function getMoney() {
      //申请提现
      this.dialogFormVisible3 = true;

      // this.dialogFormVisible3=true
    },
    setUserAlipay: function setUserAlipay() {
      //申请提现
      this.dialogFormVisible4 = true;

      // this.dialogFormVisible3=true
    }
  }
};