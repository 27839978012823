"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", [_c("el-form", {
    staticStyle: {
      "padding-top": "20px",
      "padding-left": "20px"
    },
    attrs: {
      inline: true,
      model: _vm.formInline
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "搜索"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.formInline.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.formInline, "keyword", $$v);
      },
      expression: "formInline.keyword"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.formInline.active,
      callback: function callback($$v) {
        _vm.$set(_vm.formInline, "active", $$v);
      },
      expression: "formInline.active"
    }
  }, [_c("el-option", {
    attrs: {
      label: "待审核",
      value: "待审核"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "全部",
      value: ""
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "small"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v("搜索")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "default",
      icon: "el-icon-search",
      size: "small"
    },
    on: {
      click: _vm.reload
    }
  }, [_vm._v("重置")])], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticStyle: {
      padding: "0 20px"
    },
    attrs: {
      md: 24
    }
  }, [_c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("产品名和图片上只要包含任意一个敏感词就拒绝，敏感词：京东，礼品卡，e卡，易卡，E卡，劵，礼包")]), _vm._v(" "), _c("RestTable", {
    ref: "target",
    attrs: {
      model: "/Goods/",
      keyword: this.formInline.keyword,
      active: this.formInline.active
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "60"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "id",
      label: "商品id"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "goodNo",
      label: "商品编号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "商品名称",
      width: "250"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "active",
      label: "是否上架"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.active == "上架" ? _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v("上架")]) : scope.row.active == "下架" ? _c("el-tag", {
          attrs: {
            type: "warning"
          }
        }, [_vm._v("下架")]) : scope.row.active == "待审核" ? _c("el-tag", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v("待审核")]) : _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v("拒绝")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "cost_money",
      label: "成本金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.cost_money) + "元")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "money",
      label: "扣款金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.money) + "元")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "cost_num",
      label: "扣款期数"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.cost_num) + "期")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "create_time",
      label: "创建时间",
      width: "250"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "",
      label: "产品图片",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            round: "",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.getimgClick(scope.row);
            }
          }
        }, [_vm._v("查看产品图片")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            round: "",
            size: "small",
            plain: ""
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(scope.row, "上架");
            }
          }
        }, [_vm._v("通过")]), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "danger",
            round: "",
            size: "small",
            plain: ""
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(scope.row, "拒绝");
            }
          }
        }, [_vm._v("拒绝")])];
      }
    }])
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;