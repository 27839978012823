"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  data: function data() {
    return {
      userData: [{
        name: '王小虎',
        //姓名
        phone: '178243172',
        //手机号
        shopNum: 1,
        //签约店铺
        signNum: 2,
        //签约单数
        signMoney: 1000 //签约金额
      }, {
        name: '王小虎',
        //姓名
        phone: '178243172',
        //手机号
        shopNum: 1,
        //签约店铺
        signNum: 2,
        //签约单数
        signMoney: 1000 //签约金额
      }, {
        name: '王小虎',
        //姓名
        phone: '178243172',
        //手机号
        shopNum: 1,
        //签约店铺
        signNum: 2,
        //签约单数
        signMoney: 1000 //签约金额
      }, {
        name: '王小虎',
        //姓名
        phone: '178243172',
        //手机号
        shopNum: 1,
        //签约店铺
        signNum: 2,
        //签约单数
        signMoney: 1000 //签约金额
      }]
    };
  }
};