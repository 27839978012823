"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dashboard"
  }, [_c("el-container", [_c("div", {
    staticClass: "top_box"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("\n        Hi " + _vm._s(_vm.name) + "，"), _c("span", {
    staticStyle: {
      "font-size": "24px",
      "margin-left": "10px"
    }
  }, [_vm._v("欢迎回来 "), _c("i", {
    staticClass: "el-icon-message-solid",
    staticStyle: {
      color: "#e7b139",
      "font-size": "28px",
      "margin-right": "10px"
    }
  })])]), _vm._v(" "), _c("div", [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    model: {
      value: _vm.value1,
      callback: function callback($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.getIndexData
    }
  }, [_vm._v("查看")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.resetData
    }
  }, [_vm._v("重置")])], 1)]), _vm._v(" "), _vm.data.length == 0 ? _c("el-skeleton") : _vm._e(), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAdmin,
      expression: "isAdmin"
    }],
    staticClass: "card"
  }, [_c("el-row", [_c("h2", {
    staticClass: "cardTitle",
    staticStyle: {
      "margin-left": "15px"
    }
  }, [_vm._v("收益概览")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #378fb4"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      "background-color": "#85ccf3",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.contract_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("合同费收入")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #3ab8d6"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      "background-color": "#86e0f6",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.rate_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("订单服务费收入")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #64bd81"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      "background-color": "#bbddc1",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.hkh_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("划扣手续费收入")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #e0c570"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      "background-color": "#f7d99d",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.pay_sum, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("划扣流水")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #378fb4"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      "background-color": "#85ccf3",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.pay_all_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("支付订单金额")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #3ab8d6"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      "background-color": "#86e0f6",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.rate_money_all / 100, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("订单服务费抽成（0.01）")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #64bd81"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      "background-color": "#bbddc1",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum((_vm.data.hkh_money + _vm.data.pay_sum) / 1000 * 7, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("划扣抽成（0.007）")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #e0c570"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      "background-color": "#f7d99d",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.data.ensign_sum) + "笔")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("签约订单数")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #378fb4"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      "background-color": "#85ccf3",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.hkhd_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("按时划扣回款金额")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #3ab8d6"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      "background-color": "#86e0f6",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.data.hkhd_num) + "期")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("按时划扣回款期数")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #64bd81"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      "background-color": "#bbddc1",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.hkhy_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("逾期划扣回款金额")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #e0c570"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      "background-color": "#f7d99d",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.data.hkhy_num) + "期")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("逾期划扣回款期数")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #378fb4"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      "background-color": "#85ccf3",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.money_hkhk_tj, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("划扣回款总金额")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #3ab8d6"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      "background-color": "#86e0f6",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.money_yetx_tj, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("提现总金额")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #64bd81"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      "background-color": "#bbddc1",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.money_htshc_tj, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("商户承担合同费用")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #64bd81"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      "background-color": "#bbddc1",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.money_yczf_tj, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("预存支付宝总金额")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #64bd81"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      "background-color": "#bbddc1",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.money_qita_tj, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("其他充值")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("h2", {
    staticClass: "cardTitle",
    staticStyle: {
      "margin-left": "15px",
      "margin-bottom": "20px"
    }
  }, [_vm._v("店铺概览")]), _vm._v(" "), _c("el-row", [_c("div", {
    staticClass: "subtitle"
  }, [_vm._v("\n          关键指标\n        ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      "background-color": "#0071ee",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.all_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("订单总值")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      "background-color": "#0071ee",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.pay_sum, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("收到划扣流水")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      "background-color": "#0071ee",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.data.pay_num) + "笔")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("已支付订单")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-folder-checked item_icon",
    staticStyle: {
      "background-color": "#0071ee",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.alipay_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray",
      "font-size": "14px"
    }
  }, [_vm._v("可提现金额(低于50元不可提现)")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme"
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-folder-checked item_icon",
    staticStyle: {
      "background-color": "#0071ee",
      "border-radius": "50%",
      padding: "10px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "32px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.money_wrz_tj, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray",
      "font-size": "14px"
    }
  }, [_vm._v("未入账金额")])]), _vm._v(" "), _c("div", {
    staticClass: "imgbang"
  })])])], 1), _vm._v(" "), _c("el-row", [_c("div", {
    staticClass: "subtitle",
    staticStyle: {
      "margin-bottom": "20px",
      "margin-top": "20px"
    }
  }, [_vm._v("\n          详细数据")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "carditem"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("span", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v("收入数据")])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("el-row", [_c("div", {
    staticClass: "info1"
  }, [_c("div", {
    staticClass: "img"
  }, [_vm._v("\n                    ￥" + _vm._s(this.formatNum(_vm.data.all_money, 2)) + "\n                  ")]), _vm._v(" "), _c("div", {
    staticClass: "txt"
  }, [_vm._v("\n                    订单总值(元)\n                  ")])])]), _vm._v(" "), _c("el-row", [_c("div", {
    staticClass: "info1"
  }, [_c("div", {
    staticClass: "img lightimg"
  }, [_vm._v("\n                    ￥" + _vm._s(this.formatNum(_vm.data.pay_sum, 2)) + "\n                  ")]), _vm._v(" "), _c("div", {
    staticClass: "txt"
  }, [_vm._v("\n                    已收到划扣流水(元)\n                  ")])])])], 1)])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "carditem",
    staticStyle: {
      border: "1px solid #fe834c"
    }
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      "background-color": "#fe834c"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v("投诉数据")])]), _vm._v(" "), _c("div", {
    staticClass: "content",
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "space-around"
    }
  }, [_c("div", {
    staticClass: "info2"
  }, [_vm._v("投诉订单："), _c("div", [_vm._v(_vm._s(_vm.data.ts_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info2",
    staticStyle: {
      border: "3px solid #8bc43d"
    }
  }, [_vm._v("代扣投诉："), _c("div", [_vm._v(_vm._s(_vm.data.dk_ts_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info2",
    staticStyle: {
      border: "3px solid #f2d711"
    }
  }, [_vm._v("代扣投诉率："), _c("div", [_vm._v(_vm._s(_vm.data.dk_all_num) + "%")])])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "carditem",
    staticStyle: {
      border: "1px solid #8b56f7"
    }
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      "background-color": "#8b56f7"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v("订单数据")])]), _vm._v(" "), _c("div", {
    staticClass: "content",
    staticStyle: {
      display: "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "100%",
      "background-color": "#9c41fe"
    }
  }, [_vm._v("正常订单：" + _vm._s(_vm.data.zc_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "95%",
      "background-color": "#9749fc"
    }
  }, [_vm._v("待归还：" + _vm._s(_vm.data.dgh_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "85%",
      "background-color": "#9251fa"
    }
  }, [_vm._v("已支付新订单：" + _vm._s(_vm.data.pay_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "80%",
      "background-color": "#8b5bf8"
    }
  }, [_vm._v("待支付新订单：" + _vm._s(_vm.data.to_pay_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "75%",
      "background-color": "#8468f5"
    }
  }, [_vm._v("待签约新订单：" + _vm._s(_vm.data.to_deduct_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "70%",
      "background-color": "#7b74f3"
    }
  }, [_vm._v("完成扣款订单：" + _vm._s(_vm.data.dk_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "65%",
      "background-color": "#7382ef"
    }
  }, [_vm._v("已完成订单：" + _vm._s(_vm.data.h_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "60%",
      "background-color": "#6a90ed"
    }
  }, [_vm._v("已完成：" + _vm._s(_vm.data.ywc_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "55%",
      "background-color": "#629cea"
    }
  }, [_vm._v("已支付续费订单：" + _vm._s(_vm.data.xf_pay_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "50%",
      "background-color": "#5aa9e7"
    }
  }, [_vm._v("代扣续费订单：" + _vm._s(_vm.data.xf_dk_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "45%",
      "background-color": "#52b6e4"
    }
  }, [_vm._v("逾期订单：" + _vm._s(_vm.data.yq_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "40%",
      "background-color": "#4cc0e2"
    }
  }, [_vm._v("违约订单：" + _vm._s(_vm.data.vy_num) + "个")]), _vm._v(" "), _c("div", {
    staticClass: "info3",
    staticStyle: {
      width: "40%",
      "background-color": "#46c9e0"
    }
  }, [_vm._v("续费订单：" + _vm._s(_vm.data.xf_num) + "个")])])])])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;