"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("b", {
    staticStyle: {
      color: "#409EFF",
      padding: "0 10px"
    }
  }, [_vm._v(_vm._s(_vm.content))]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "tiny",
      type: "primary"
    },
    on: {
      click: _vm.copyIt
    }
  }, [_vm._v("复制")])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;