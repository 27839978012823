"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _adaptive = _interopRequireDefault(require("./adaptive"));
var install = function install(Vue) {
  Vue.directive('el-height-adaptive-table', _adaptive.default);
};
if (window.Vue) {
  window['el-height-adaptive-table'] = _adaptive.default;
  Vue.use(install); // eslint-disable-line
}
_adaptive.default.install = install;
var _default = exports.default = _adaptive.default;