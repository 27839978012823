"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMoney = addMoney;
exports.changePassword = changePassword;
exports.createAlipay = createAlipay;
exports.createUser = createUser;
exports.deleteUser = deleteUser;
exports.getAlipayList = getAlipayList;
exports.getInfo = getInfo;
exports.getUser = getUser;
exports.getUserList = getUserList;
exports.login = login;
exports.logout = logout;
exports.updateAlipatStatus = updateAlipatStatus;
exports.updateAlipay = updateAlipay;
exports.updateUser = updateUser;
var _request = _interopRequireDefault(require("@/utils/request"));
function login(data) {
  return (0, _request.default)({
    url: '/token1/',
    method: 'post',
    data: data
  });
}
function logout() {
  return (0, _request.default)({
    url: '/token/black/',
    method: 'get'
  });
}
function getInfo() {
  // console.log('getInfo')
  return (0, _request.default)({
    url: '/system/user/info/',
    method: 'get'
  });
}
function getAlipayList(query) {
  return (0, _request.default)({
    url: '/system/alipayconfig/',
    method: 'get',
    params: query
  });
}
function createAlipay(data) {
  return (0, _request.default)({
    url: '/system/alipayconfig/',
    method: 'post',
    data: data
  });
}
function updateAlipay(id, data) {
  return (0, _request.default)({
    url: "/system/alipayconfig/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function updateAlipatStatus(id, data) {
  return (0, _request.default)({
    url: "/system/alipayconfig/alipay_status_update/",
    method: 'put',
    data: data
  });
}
function getUserList(query) {
  return (0, _request.default)({
    url: '/system/user/',
    method: 'get',
    params: query
  });
}
function getUser(id) {
  return (0, _request.default)({
    url: "/system/user/".concat(id, "/"),
    method: 'get'
  });
}
function createUser(data) {
  return (0, _request.default)({
    url: '/system/user/',
    method: 'post',
    data: data
  });
}
function updateUser(id, data) {
  return (0, _request.default)({
    url: "/system/user/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteUser(id, data) {
  return (0, _request.default)({
    url: "/system/user/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
function changePassword(data) {
  return (0, _request.default)({
    url: '/system/user/password/',
    method: 'put',
    data: data
  });
}
function addMoney(data) {
  return (0, _request.default)({
    url: '/system/user/money/',
    method: 'put',
    data: data
  });
}