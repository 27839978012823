"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.string.pad-start");
var _request = _interopRequireDefault(require("@/utils/request"));
var _RestTable = _interopRequireDefault(require("@/components/RestTable.vue"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _formatMixin = require("@/utils/formatMixin");
var _default = exports.default = {
  components: {
    RestTable: _RestTable.default,
    Pagination: _index.default
  },
  mixins: [_formatMixin.formatMixin],
  data: function data() {
    return {
      // 顶部搜索框form数据
      formInline: {
        keyword: '',
        //搜索关键词
        userphone: '',
        orderNo: '',
        username: '',
        start_time: '',
        end_time: '',
        remark: '余额提现,划扣回款,预存支付宝',
        gmoney: '',
        lmoney: ''
      },
      datenum: [],
      money: 0,
      // 商家余额
      moneyList: {
        count: 0
      },
      // 划扣流水
      listQuery: {
        page: 1,
        page_size: 10
      },
      listLoading: false
    };
  },
  created: function created() {
    this.getMoneyList();
  },
  computed: {
    start_time: function start_time() {
      return this.datenum[0] ? this.formatDate(this.datenum[0]) : '';
    },
    end_time: function end_time() {
      return this.datenum[1] ? this.formatDate(this.datenum[1]) : '';
    }
  },
  methods: {
    // 格式化日期,需要加上时分秒的数据
    formatDate: function formatDate(date) {
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
      var day = String(date.getDate()).padStart(2, '0');
      var hour = String(date.getHours()).padStart(2, '0');
      var minute = String(date.getMinutes()).padStart(2, '0');
      var second = String(date.getSeconds()).padStart(2, '0');
      return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(minute, ":").concat(second);
    },
    resetFilter: function resetFilter() {
      this.formInline.keyword = '';
      this.formInline.userphone = '';
      this.formInline.orderNo = '';
      this.formInline.username = '';
      this.formInline.start_time = '';
      this.formInline.end_time = '';
      this.formInline.remark = '余额提现,划扣回款,预存支付宝';
      this.formInline.gmoney = '';
      this.formInline.lmoney = '';
      this.datenum = [];
      this.getMoneyList();
    },
    // 搜索
    handleFilter: function handleFilter() {
      var _this = this;
      this.listLoading = true;
      // 王工封装
      // type 2 为支付宝账号流水
      (0, _request.default)({
        url: "/system/order/getUserRecord/",
        method: 'put',
        data: {
          shop_id: this.$user.data.shop,
          type: 2,
          is_pay: 1,
          remark: this.formInline.remark,
          value: this.formInline.keyword,
          userphone: this.formInline.userphone,
          orderNo: this.formInline.orderNo,
          username: this.formInline.username,
          start_time: this.start_time,
          end_time: this.end_time,
          gmoney: this.formInline.gmoney,
          lmoney: this.formInline.lmoney
        }
      }).then(function (response) {
        if (response.data) {
          _this.moneyList = response.data.data;
        }
        _this.listLoading = false;
      });
    },
    // 获取划扣流水——done
    getMoneyList: function getMoneyList() {
      var _this2 = this;
      this.listLoading = true;
      // 王工封装
      // type 2 为支付宝账号流水
      (0, _request.default)({
        url: "/system/order/getUserRecord/",
        method: 'put',
        data: {
          shop_id: this.$user.data.shop,
          type: 2,
          is_pay: 1,
          remark: this.formInline.remark,
          page: this.listQuery.page,
          page_size: this.listQuery.page_size,
          value: this.formInline.keyword,
          userphone: this.formInline.userphone,
          orderNo: this.formInline.orderNo,
          username: this.formInline.username,
          start_time: this.start_time,
          end_time: this.end_time,
          gmoney: this.formInline.gmoney,
          lmoney: this.formInline.lmoney
        }
      }).then(function (response) {
        if (response.data) {
          _this2.moneyList = response.data.data;
        }
        _this2.listLoading = false;
      });
    }
  }
};