"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "block"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v("所属行业")]), _vm._v(" "), _c("el-cascader", {
    attrs: {
      options: _vm.options
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;