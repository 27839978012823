"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sidebar-logo-container",
    class: {
      collapse: _vm.collapse
    }
  }, [_c("transition", {
    attrs: {
      name: "sidebarLogoFade"
    }
  }, [_vm.collapse ? _c("router-link", {
    key: "collapse",
    staticClass: "sidebar-logo-link",
    attrs: {
      to: "/"
    }
  }, [_c("h1", {
    staticClass: "sidebar-title"
  }, [_vm._v(_vm._s(_vm.title) + " ")])]) : _c("router-link", {
    key: "expand",
    staticClass: "sidebar-logo-link",
    attrs: {
      to: "/"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px",
      "background-color": "#00a0e9"
    },
    attrs: {
      src: require("../../../assets/avatar.png"),
      alt: ""
    }
  }), _vm._v(" "), _c("h1", {
    staticClass: "sidebar-title"
  }, [_vm._v(_vm._s(_vm.title) + " ")])])])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;