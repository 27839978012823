"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    staticStyle: {
      padding: "15px"
    },
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      md: 24
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-bottom": "15px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "small",
      disabled: ""
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v("搜索")]), _vm._v(" "), _c("RestTable", {
    ref: "target",
    attrs: {
      model: "/PreUser/"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "60"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "company_name",
      label: "公司名称"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "company_scc",
      label: "统一社会信用代码"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "real_name",
      label: "姓名"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "mobile",
      label: "手机号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "reg_ip",
      label: "申请ip"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "create_time",
      label: "提交时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "状态",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row["is_wfl"] ? _c("el-tag", [_vm._v("未审核")]) : _vm._e(), _vm._v(" "), scope.row["is_approve"] ? _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v("审核通过")]) : _vm._e(), _vm._v(" "), scope.row["is_reject"] ? _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v("审核拒绝")]) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row["is_wfl"] ? _c("el-button", {
          attrs: {
            type: "primary",
            round: "",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(scope.row);
            }
          }
        }, [_vm._v("立即审核")]) : _vm._e(), _vm._v(" "), scope.row["is_approve"] ? _c("el-button", {
          attrs: {
            type: "success",
            round: "",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(scope.row);
            }
          }
        }, [_vm._v("查看资料")]) : _vm._e(), _vm._v(" "), scope.row["is_reject"] ? _c("el-button", {
          attrs: {
            type: "info",
            round: "",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(scope.row);
            }
          }
        }, [_vm._v("查看资料")]) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;