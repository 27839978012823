"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }), _vm._v(" "), _c("breadcrumb", {
    staticClass: "breadcrumb-container"
  }), _vm._v(" "), _c("div", {
    staticClass: "right-menu"
  }, [_c("el-dropdown", {
    staticClass: "avatar-container",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("div", {
    staticClass: "user-avatar"
  }, [_vm._v("\n          更多设置"), _c("i", {
    staticClass: "el-icon-arrow-down"
  })])]), _vm._v(" "), _c("el-dropdown-menu", {
    staticClass: "user-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("router-link", {
    attrs: {
      to: "/"
    }
  }, [_c("el-dropdown-item", [_vm._v("\n            首页\n          ")])], 1), _vm._v(" "), _c("router-link", {
    attrs: {
      to: "/changepassword"
    }
  }, [_c("el-dropdown-item", {
    attrs: {
      divided: ""
    }
  }, [_vm._v("\n            修改密码\n          ")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      divided: ""
    },
    nativeOn: {
      click: function click($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    }
  }, [_vm._v("退出")])])], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "right-menu"
  }, [_c("ShopSelect")], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;