"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "ruleForm",
    staticClass: "demo-ruleForm",
    attrs: {
      model: _vm.ruleForm,
      rules: _vm.rules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "标签名称",
      prop: "title",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "name", $$v);
      },
      expression: "ruleForm.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      "label-width": _vm.labelWidth
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("ruleForm");
      }
    }
  }, [_vm._v(" 立即创建 ")]), _vm._v(" "), _c("el-button", {
    on: {
      click: function click($event) {
        return _vm.resetForm("ruleForm");
      }
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;