"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dashboard",
    staticStyle: {
      "padding-bottom": "20px"
    }
  }, [_vm.isLoading ? _c("div", {
    staticClass: "fullscreen-loading"
  }, [_vm._m(0)]) : _c("div", {
    staticClass: "chartreport"
  }, [_c("el-page-header", {
    staticStyle: {
      margin: "10px 0"
    },
    attrs: {
      content: "详情页面"
    },
    on: {
      back: _vm.goBack
    }
  }), _vm._v(" "), _c("el-card", {
    staticClass: "info_card"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("91履约报告"), _c("span", {
    staticClass: "time"
  }, [_vm._v("报告出具时间：" + _vm._s(_vm.formatTime(_vm.userInfo.create_time)))])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "item_title",
    staticStyle: {
      "font-size": "28px"
    }
  }, [_vm._v(_vm._s(_vm.userInfo.name))]), _vm._v(" "), _c("el-tag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userInfo.is_xyhf == 1,
      expression: "userInfo.is_xyhf == 1"
    }],
    staticStyle: {
      "border-radius": "15px"
    },
    attrs: {
      type: "default",
      hit: ""
    }
  }, [_vm._v("先用后付")]), _vm._v(" "), _c("el-tag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userInfo.is_zj == 1,
      expression: "userInfo.is_zj == 1"
    }],
    staticStyle: {
      "border-radius": "15px"
    },
    attrs: {
      type: "success",
      hit: ""
    }
  }, [_vm._v("租机")]), _vm._v(" "), _c("el-tag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userInfo.is_wd == 1,
      expression: "userInfo.is_wd == 1"
    }],
    staticStyle: {
      "border-radius": "15px"
    },
    attrs: {
      type: "warning",
      hit: ""
    }
  }, [_vm._v("网贷")]), _vm._v(" "), _c("el-tag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userInfo.is_hszj == 1,
      expression: "userInfo.is_hszj == 1"
    }],
    staticStyle: {
      "border-radius": "15px"
    },
    attrs: {
      type: "danger",
      hit: ""
    }
  }, [_vm._v("回收中介")])], 1), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "item_title"
  }, [_vm._v("年龄：" + _vm._s(_vm.userInfo.ages))]), _vm._v(" "), _c("span", {
    staticClass: "item_title"
  }, [_vm._v("性别：" + _vm._s(_vm.userInfo.sex))]), _vm._v(" "), _c("span", [_vm._v("身份证号：" + _vm._s(_vm.userInfo.card_code))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "item_title"
  }, [_vm._v("户籍所在地：" + _vm._s(_vm.userInfo.address))])]), _vm._v(" "), _c("div", {
    staticClass: "type"
  }, [_c("img", {
    staticClass: "img",
    attrs: {
      src: require("../../assets/wechaticon.png"),
      alt: ""
    }
  }), _vm._v(" "), _c("img", {
    staticClass: "img",
    attrs: {
      src: require("../../assets/alipayicon.png"),
      alt: ""
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v(_vm._s(_vm.time_des))])])]), _vm._v(" "), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "item2"
  }, [_c("span", [_vm._v("活跃率(总计)：")]), _vm._v(" "), _c("span", {
    staticClass: "item_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.missing_percentage, 2)) + "%")])]), _vm._v(" "), _c("div", {
    staticClass: "item2",
    staticStyle: {
      "border-left": "10px solid #14e2b4"
    }
  }, [_c("span", [_vm._v("最长未使用天数：")]), _vm._v(" "), _c("span", {
    staticClass: "item_num"
  }, [_vm._v(_vm._s(_vm.longest_missing_sequence) + "天")])]), _vm._v(" "), _c("div", {
    staticClass: "item2"
  }, [_c("span", [_vm._v("银行卡持有情况：")]), _vm._v(" "), _c("span", {
    staticClass: "item_num"
  }, [_vm._v("储蓄卡" + _vm._s(_vm.count) + "张，信用卡" + _vm._s(_vm.count1) + "张")])]), _vm._v(" "), _c("div", {
    staticClass: "item2",
    staticStyle: {
      "border-left": "10px solid #14e2b4",
      "margin-bottom": "0"
    }
  }, [_c("span", [_vm._v("总收支情况：")]), _vm._v(" "), _c("span", {
    staticClass: "item_num"
  }, [_vm._v("总收入：" + _vm._s(_vm.allList.sumS) + "元，总支出：" + _vm._s(this.formatNum(_vm.allList.sumZ, 2)) + "元\n                        ")])])])])]), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/alllist.png"),
      alt: ""
    }
  }), _vm._v("收支概览")]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "0 20px"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isAllLoading,
      expression: "isAllLoading"
    }],
    staticStyle: {
      margin: "15px 0"
    },
    attrs: {
      data: _vm.moneyByTypeList,
      stripe: "",
      border: "",
      "header-cell-style": {
        backgroundColor: "#0094fe",
        color: "#fff",
        fontWeight: 600
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50px"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "title",
      label: "分类"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [_c("img", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: scope.row.title == "微信",
            expression: "scope.row.title == '微信'"
          }],
          staticClass: "img",
          staticStyle: {
            width: "30px",
            "margin-right": "10px"
          },
          attrs: {
            src: require("../../assets/wechaticon.png"),
            alt: ""
          }
        }), _vm._v(" "), _c("img", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: scope.row.title == "支付宝",
            expression: "scope.row.title == '支付宝'"
          }],
          staticClass: "img",
          staticStyle: {
            width: "30px",
            "margin-right": "10px"
          },
          attrs: {
            src: require("../../assets/alipayicon.png"),
            alt: ""
          }
        }), _vm._v("\n                                        " + _vm._s(scope.row.title) + "\n                                    ")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "data1",
      label: "收入金额"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "data2",
      label: "支出金额"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "data3",
      label: "不计收支"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "data4",
      label: "其他金额"
    }
  })], 1)], 1)])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/alllist.png"),
      alt: ""
    }
  }), _vm._v("账单明细\n                    ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px"
    }
  }, [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.formInline
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键词搜索"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入想要搜索的内容"
    },
    model: {
      value: _vm.formInline.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.formInline, "keyword", $$v);
      },
      expression: "formInline.keyword"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "small"
    },
    on: {
      click: _vm.getAllList
    }
  }, [_vm._v("搜索")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-refresh-right",
      size: "small"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v("重置")])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      flex: "1"
    }
  }, [_c("div", [_vm._v("总收入："), _c("span", {
    staticStyle: {
      color: "red",
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.allList.sumS) + "元")])]), _vm._v(" "), _c("div", [_vm._v(" 总支出："), _c("span", {
    staticStyle: {
      color: "red",
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.allList.sumZ, 2)) + "元")])]), _vm._v(" "), _c("div", [_vm._v("不计收支："), _c("span", {
    staticStyle: {
      color: "red",
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.allList.sumB, 2)) + "元")])]), _vm._v(" "), _c("div", [_vm._v("其他："), _c("span", {
    staticStyle: {
      color: "red",
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.allList.sumQ) + "元")])])]), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isAllLoading,
      expression: "isAllLoading"
    }],
    staticStyle: {
      margin: "15px 0"
    },
    attrs: {
      data: _vm.allList.results,
      stripe: "",
      border: "",
      "header-cell-style": {
        backgroundColor: "#0094fe",
        color: "#fff",
        fontWeight: 600
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "交易平台"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                                    " + _vm._s(scope.row.type == 1 ? "支付宝" : "微信") + "\n                                ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_time",
      label: "交易时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "money",
      label: "金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                                    " + _vm._s(scope.row.money) + "元\n                                ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_from",
      label: "交易方",
      width: "width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                                    " + _vm._s(scope.row.order_from) + "\n                                    "), _c("el-button", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isAdmin,
            expression: "isAdmin"
          }],
          attrs: {
            type: "primary",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.goAddShop(scope.row.order_from);
            }
          }
        }, [_vm._v("添加")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_des",
      label: "交易描述"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_type",
      label: "交易类型",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "re_or_ex",
      label: "账单类型",
      width: "width"
    }
  })], 1), _vm._v(" "), _c("Pagination", {
    staticStyle: {
      "margin-top": "10px",
      "text-align": "center"
    },
    attrs: {
      total: _vm.allList.count,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.page_size,
      autoScroll: false
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "page_size", $event);
      },
      pagination: _vm.getAllList
    }
  })], 1)])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/day.png"),
      alt: ""
    }
  }), _vm._v("\n                        时段交易分析\n                        ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "background-color": "#f2f5fc",
      padding: "15px 20px",
      "border-left": "5px solid #0094fe",
      margin: "10px 20px"
    }
  }, [_c("i", {
    staticClass: "el-icon-info"
  }), _vm._v(" 统计用户日常交易次数，洞悉用户消费时间偏好。统计单位：次\n                    ")]), _vm._v(" "), _c("div", {
    staticClass: "desc",
    staticStyle: {
      display: "flex",
      "margin-top": "20px",
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "margin-right": "30px"
    }
  }, [_c("div", {
    staticStyle: {
      width: "30px",
      height: "30px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../../assets/alipayicon.png"),
      alt: ""
    }
  })]), _vm._v(" "), _c("div", {
    staticClass: "title1",
    staticStyle: {
      margin: "0 20px"
    }
  }, [_c("div", [_vm._v("最高活跃次数")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.max_hourly_totals_alipay) + "次")])]), _vm._v(" "), _c("div", {
    staticClass: "title1"
  }, [_c("div", [_vm._v("最低活跃次数")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.min_hourly_totals_alipay) + "次")])])]), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      width: "30px",
      height: "30px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../../assets/wechaticon.png"),
      alt: ""
    }
  })]), _vm._v(" "), _c("div", {
    staticClass: "title1",
    staticStyle: {
      margin: "0 20px"
    }
  }, [_c("div", [_vm._v("最高活跃次数")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.max_hourly_totals_wx) + "次")])]), _vm._v(" "), _c("div", {
    staticClass: "title1"
  }, [_c("div", [_vm._v("最低活跃次数")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.min_hourly_totals_wx) + "次")])])])]), _vm._v(" "), _c("BarChart", {
    ref: "barChart1",
    attrs: {
      option: _vm.timedata
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/month.png"),
      alt: ""
    }
  }), _vm._v("\n                        月度交易分析\n                        ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "background-color": "#f2f5fc",
      padding: "15px 20px",
      "border-left": "5px solid #0094fe",
      margin: "10px 20px"
    }
  }, [_c("i", {
    staticClass: "el-icon-info"
  }), _vm._v(" 统计用户当月累计交易次数，了解用户全年交易趋势。统计单位：次\n                    ")]), _vm._v(" "), _c("div", {
    staticClass: "desc",
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      width: "45px",
      height: "45px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../../assets/tongji.png"),
      alt: ""
    }
  })]), _vm._v(" "), _c("div", {
    staticClass: "title1",
    staticStyle: {
      margin: "0 20px"
    }
  }, [_c("div", [_vm._v("活跃率(总计)")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-weight": "600",
      "margin-top": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.missing_percentage, 2)) + "%\n                                ")])]), _vm._v(" "), _c("div", {
    staticClass: "title1"
  }, [_c("div", [_vm._v("最长未使用天数")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-weight": "600",
      "margin-top": "10px"
    }
  }, [_vm._v(_vm._s(_vm.longest_missing_sequence) + "天\n                                ")])])])]), _vm._v(" "), _c("BarChart", {
    ref: "barChart2",
    attrs: {
      option: _vm.monthdata
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/alllist.png"),
      alt: ""
    }
  }), _vm._v("时段收入/支出分析\n                    ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "background-color": "#f2f5fc",
      padding: "15px 20px",
      "border-left": "5px solid #0094fe",
      margin: "10px 20px"
    }
  }, [_c("i", {
    staticClass: "el-icon-info"
  }), _vm._v(" 统计用户日常交易金额，洞悉用户消费金额偏好。统计单位：元\n                    ")]), _vm._v(" "), _c("LineChart", {
    ref: "lineChart1",
    attrs: {
      option: _vm.incomedata1
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/alllist.png"),
      alt: ""
    }
  }), _vm._v("月均收入/支出分析\n                    ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "background-color": "#f2f5fc",
      padding: "15px 20px",
      "border-left": "5px solid #0094fe",
      margin: "10px 20px"
    }
  }, [_c("i", {
    staticClass: "el-icon-info"
  }), _vm._v(" 统计用户账单周期内的收入支出数据，提前规避负债违约风险。统计单位：元\n                    ")]), _vm._v(" "), _c("LineChart", {
    ref: "lineChart1",
    attrs: {
      option: _vm.incomedata
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/banklist.png"),
      alt: ""
    }
  }), _vm._v("\n                        银行卡使用分析\n                        ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "background-color": "#f2f5fc",
      padding: "15px 20px",
      "border-left": "5px solid #0094fe",
      margin: "10px 20px"
    }
  }, [_c("i", {
    staticClass: "el-icon-info"
  }), _vm._v(" 统计用户该渠道下储蓄卡和信用卡使用情况。其中"), _c("span", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("储蓄卡" + _vm._s(_vm.count) + "张，信用卡" + _vm._s(_vm.count1) + "张")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px"
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.bankList,
      border: "",
      stripe: "",
      "header-cell-style": {
        backgroundColor: "#0094fe",
        color: "#fff",
        fontWeight: 600
      },
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "50",
      label: "序号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "银行名称",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "card_type",
      label: "卡类型",
      width: "width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                                    " + _vm._s(scope.row.card_type == 1 ? "储蓄卡" : "信用卡") + "\n                                ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "使用平台",
      width: "width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: scope.row.type == 1 || scope.row.type == 3,
            expression: "scope.row.type == 1 || scope.row.type == 3"
          }],
          attrs: {
            type: "success",
            effect: "plain"
          }
        }, [_vm._v("微信")]), _vm._v(" "), _c("el-tag", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: scope.row.type == 2 || scope.row.type == 3,
            expression: "scope.row.type == 2 || scope.row.type == 3"
          }],
          attrs: {
            type: "primary",
            effect: "plain"
          }
        }, [_vm._v("支付宝")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "code",
      label: "银行卡尾号",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "num",
      label: "交易次数",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "per",
      label: "使用率",
      width: "width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            type: "primary",
            effect: "plain"
          }
        }, [_vm._v(_vm._s(scope.row.per) + "%")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "moneyS",
      label: "收入金额",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "moneyZ",
      label: "支出金额",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "moneyQ",
      label: "其他金额",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "moneyB",
      label: "不计收支",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            plain: "",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.handleBackClick(scope.row);
            }
          }
        }, [_vm._v("\n                                        查看明细\n                                    ")])];
      }
    }])
  })], 1)], 1)])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "银行卡使用明细",
      visible: _vm.backDetaildialogVisible,
      width: "width",
      "before-close": _vm.resetBackDetail
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.backDetaildialogVisible = $event;
      }
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.backcardDetail.results
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "交易平台"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                        " + _vm._s(scope.row.type == 1 ? "支付宝" : "微信") + "\n                    ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_time",
      label: "交易时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "money",
      label: "金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                        " + _vm._s(scope.row.money) + "元\n                    ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_from",
      label: "交易方",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "order_des",
      label: "交易描述"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pay_type",
      label: "交易类型",
      width: "width"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "re_or_ex",
      label: "账单类型",
      width: "width"
    }
  })], 1), _vm._v(" "), _c("Pagination", {
    staticStyle: {
      "margin-top": "10px",
      "text-align": "center"
    },
    attrs: {
      total: _vm.backcardDetail.count,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.page_size,
      autoScroll: false
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "page_size", $event);
      },
      pagination: _vm.handlePagination
    }
  })], 1), _vm._v(" "), _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/shoplist.png"),
      alt: ""
    }
  }), _vm._v("交易商户TOP15\n                        ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "background-color": "#f2f5fc",
      padding: "15px 20px",
      "border-left": "5px solid #0094fe",
      margin: "10px 20px"
    }
  }, [_c("i", {
    staticClass: "el-icon-info"
  }), _vm._v(" 按交易次数和金额统计，仅展示前15名商户交易数据。\n                    ")]), _vm._v(" "), _c("el-tabs", {
    staticStyle: {
      padding: "0 20px"
    },
    attrs: {
      type: "card"
    },
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "近7日",
      name: "first"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "近15日",
      name: "second"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "近1个月",
      name: "third"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "近3个月",
      name: "fourth"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "近6个月",
      name: "fifth"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "近1年",
      name: "sixth"
    }
  }, [_c("ShopTable", {
    attrs: {
      tabledata: _vm.formlist1
    }
  })], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _vm.selectShop1 ? _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/xianyong.png"),
      alt: ""
    }
  }), _vm._v("先用后付\n                    ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "0 20px"
    }
  }, [_c("div", {
    staticClass: "shop_box"
  }, _vm._l(_vm.shopNameList1, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "shop_item",
      class: {
        "selected-shop": item == _vm.selectShop1
      },
      on: {
        click: function click($event) {
          return _vm.clickShop1(item);
        }
      }
    }, [item.url != "" ? _c("div", {
      staticClass: "itemimg"
    }, [_c("img", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: item.url,
        alt: ""
      }
    })]) : _c("div", {
      staticClass: "noimg"
    }, [_vm._v("\n                                    " + _vm._s(item.name.slice(0, 2)) + "\n                                ")]), _vm._v(" "), _c("div", {
      staticClass: "itemname",
      staticStyle: {
        "margin-top": "5px"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px 0"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectShop1,
      expression: "selectShop1"
    }],
    staticStyle: {
      "font-weight": "600",
      margin: "10px 0"
    }
  }, [_vm._v(_vm._s(_vm.selectShop1.name) + "，交易次数：" + _vm._s(_vm.shopList1.results.length))]), _vm._v(" "), _c("ShopTable2", {
    attrs: {
      tabledata: _vm.shopList1.results
    }
  })], 1)])])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.selectShop2 ? _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/zulin.png"),
      alt: ""
    }
  }), _vm._v("租赁")]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "0 20px"
    }
  }, [_c("div", {
    staticClass: "shop_box"
  }, _vm._l(_vm.shopNameList2, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "shop_item",
      class: {
        "selected-shop": item == _vm.selectShop2
      },
      on: {
        click: function click($event) {
          return _vm.clickShop2(item);
        }
      }
    }, [item.url != "" ? _c("div", {
      staticClass: "itemimg"
    }, [_c("img", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: item.url,
        alt: ""
      }
    })]) : _c("div", {
      staticClass: "noimg"
    }, [_vm._v(_vm._s(item.name.slice(0, 2)) + "\n                                ")]), _vm._v(" "), _c("div", {
      staticClass: "itemname",
      staticStyle: {
        "margin-top": "5px"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px 0"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectShop2,
      expression: "selectShop2"
    }],
    staticStyle: {
      "font-weight": "600",
      margin: "10px 0"
    }
  }, [_vm._v(_vm._s(_vm.selectShop2.name) + "，交易次数：" + _vm._s(_vm.shopList2.results.length))]), _vm._v(" "), _c("ShopTable2", {
    attrs: {
      tabledata: _vm.shopList2.results
    }
  })], 1)])])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.selectShop3 ? _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/wangdai.png"),
      alt: ""
    }
  }), _vm._v("网贷")]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "0 20px"
    }
  }, [_c("div", {
    staticClass: "shop_box"
  }, _vm._l(_vm.shopNameList3, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "shop_item",
      class: {
        "selected-shop": item == _vm.selectShop3
      },
      on: {
        click: function click($event) {
          return _vm.clickShop3(item);
        }
      }
    }, [item.url != "" ? _c("div", {
      staticClass: "itemimg"
    }, [_c("img", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: item.url,
        alt: ""
      }
    })]) : _c("div", {
      staticClass: "noimg"
    }, [_vm._v(_vm._s(item.name.slice(0, 2)) + "\n                                ")]), _vm._v(" "), _c("div", {
      staticClass: "itemname",
      staticStyle: {
        "margin-top": "5px"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px 0"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectShop3,
      expression: "selectShop3"
    }],
    staticStyle: {
      "font-weight": "600",
      margin: "10px 0"
    }
  }, [_vm._v(_vm._s(_vm.selectShop3.name) + "，交易次数：" + _vm._s(_vm.shopList3.results.length))]), _vm._v(" "), _c("ShopTable2", {
    attrs: {
      tabledata: _vm.shopList3.results
    }
  })], 1)])])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.selectShop4 ? _c("el-row", {
    staticClass: "chart_row",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    staticClass: "iconimg",
    attrs: {
      src: require("../../assets/huishou.png"),
      alt: ""
    }
  }), _vm._v("\n                        回收中介\n                    ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "0 20px"
    }
  }, [_c("div", {
    staticClass: "shop_box"
  }, _vm._l(_vm.shopNameList4, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "shop_item",
      class: {
        "selected-shop": item == _vm.selectShop4
      },
      on: {
        click: function click($event) {
          return _vm.clickShop4(item);
        }
      }
    }, [item.url != "" ? _c("div", {
      staticClass: "itemimg"
    }, [_c("img", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: item.url,
        alt: ""
      }
    })]) : _c("div", {
      staticClass: "noimg"
    }, [_vm._v("\n                                     " + _vm._s(item.name.slice(0, 2)) + "\n                                ")]), _vm._v(" "), _c("div", {
      staticClass: "itemname",
      staticStyle: {
        "margin-top": "5px"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "20px 0"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectShop4,
      expression: "selectShop4"
    }],
    staticStyle: {
      "font-weight": "600",
      margin: "10px 0"
    }
  }, [_vm._v(_vm._s(_vm.selectShop4.name) + "，交易次数：" + _vm._s(_vm.shopList4.results.length))]), _vm._v(" "), _c("ShopTable2", {
    attrs: {
      tabledata: _vm.shopList4.results
    }
  })], 1)])])], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "添加店铺",
      visible: _vm.dialogFormVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, _vm._l(_vm.tag_list, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1), _vm._v(" "), _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.addShop
    }
  }, [_vm._v("提交")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "custom-loading"
  }, [_c("img", {
    staticClass: "loading-image",
    staticStyle: {
      width: "600px"
    },
    attrs: {
      src: require("../../assets/2.gif"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;