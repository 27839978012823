"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.match");
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request1"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _default = exports.default = {
  components: {
    Pagination: _index.default
  },
  data: function data() {
    return {
      listLoading: true,
      pk: 0,
      list: [],
      listQuery: {
        page: 1,
        page_size: 10
      },
      isLoading: false,
      multipleSelection: []
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    setEwm: function setEwm() {
      var _this = this;
      // console.log(this.$user.data.shop)
      (0, _request.default)({
        url: "/system/report/set_ewm/?shop_id=" + this.$user.data.shop,
        method: 'get',
        data: {}
      }).then(function (response) {
        console.log(response.data);
        _this.$message({
          dangerouslyUseHTMLString: true,
          message: '<img style="width:200px;height:200px" src="' + response.data + '"></img>',
          duration: 0,
          showClose: true
        });
      });
    },
    handleClick: function handleClick(id) {
      this.$router.push({
        path: '/report/reportGetDetail',
        query: {
          id: id
        }
      });
    },
    handleCreate: function handleCreate(id) {
      var _this2 = this;
      // console.log(id)
      this.isLoading = true;
      (0, _request2.default)({
        url: '/create_report',
        method: 'post',
        data: {
          record_id: id
        }
      }).then(function (response) {
        if (response.code == 1) {
          _this2.isLoading = false;
          _this2.getList();
        }
        // console.log(response.data)
      }).catch(function (error) {
        _this2.isLoading = false;
        // console.log(error)
      });
    },
    reload: function reload() {
      // 刷新页面
      window.location.reload();
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _request.default)({
        url: "/ReportGet/",
        method: 'get',
        params: {
          page: this.listQuery.page,
          page_size: this.listQuery.page_size
        }
      }).then(function (res) {
        _this3.list = res.data;
        _this3.listLoading = false;
        // console.log(res)
      });
    },
    handleSelectionChange: function handleSelectionChange(e) {
      // console.log(e)
      this.multipleSelection = e;
    },
    confirmMultiple: function confirmMultiple() {
      var selection = this.multipleSelection;
      if (selection.length != 2) {
        this.$message.error('请选择两个报表');
        return;
      }
      if (selection[0].is_get == 0 || selection[1].is_get == 0) {
        this.$message.error('已生成的报表才能聚合');
        return;
      }

      // 定义验证规则
      var validationRules = [{
        condition: selection[0].user_id !== selection[1].user_id,
        message: '请选择同一用户的两类报表'
      }, {
        condition: selection[0].type === selection[1].type,
        message: '报表类型不能相同'
      }, {
        condition: !this.compareDateRanges(selection[0].time_des, selection[1].time_des),
        message: '时间周期必须一致'
      }];

      // 验证规则
      for (var _i = 0, _validationRules = validationRules; _i < _validationRules.length; _i++) {
        var rule = _validationRules[_i];
        if (rule.condition) {
          console.log(rule);
          this.$message.error(rule.message);
          return;
        }
      }

      // 没问题直接跳聚合报告详情页
      this.$router.push({
        path: '/report/ReportGetDetailMulit',
        query: {
          id1: selection[0].id,
          id2: selection[1].id
        }
      });
    },
    compareDateRanges: function compareDateRanges(str1, str2) {
      // 从字符串中提取日期部分
      var dateRange1 = str1.match(/(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/g);
      var dateRange2 = str2.match(/(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/g);
      if (!dateRange1 || !dateRange2 || dateRange1.length < 2 || dateRange2.length < 2) {
        console.log("日期格式不正确");
        return false;
      }

      // 将特殊的全角冒号替换为半角冒号
      var startDate1 = new Date(dateRange1[0].replace(/：/g, ':'));
      var startDate2 = new Date(dateRange2[0].replace(/：/g, ':'));

      // 比较第一个时间（年份和月份）
      if (startDate1.getFullYear() === startDate2.getFullYear() && startDate1.getMonth() === startDate2.getMonth() && startDate1.getDate() === startDate2.getDate()) {
        // 如果第一个时间的年份和月份相同，继续比较第二个时间
        var endDate1 = new Date(dateRange1[1].replace(/：/g, ':'));
        var endDate2 = new Date(dateRange2[1].replace(/：/g, ':'));
        return endDate1.getFullYear() === endDate2.getFullYear() && endDate1.getMonth() === endDate2.getMonth() && endDate1.getDate() === endDate2.getDate();
      } else {
        // 第一个时间的年份或月份不同，返回假
        return false;
      }
    }
  }
};