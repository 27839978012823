"use strict";

var _interopRequireDefault = require("/www/wwwroot/deduct_node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.removecurrentShop = removecurrentShop;
exports.removepreregister = removepreregister;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'token';
var currentShop = 'currentShop';
var preregister = 'pre-register';
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function removecurrentShop() {
  return _jsCookie.default.remove(currentShop);
}
function removepreregister() {
  return _jsCookie.default.remove(preregister);
}

// export function refreshToken() {
//   let token = getToken()
//   let data = {"token": token}
//   return request({
//     url: '/token/refresh/',
//     method: 'post',
//     data
//   })
// }