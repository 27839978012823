"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container",
    staticStyle: {
      padding: "20px"
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      type: "success",
      icon: "el-icon-plus",
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogFormVisible = true;
      }
    }
  }, [_vm._v("添加员工")]), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.staffData,
      border: "",
      stripe: "",
      "header-cell-style": {
        color: "#fff",
        fontWeight: "700",
        backgroundColor: "#409eff"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "shop_name",
      label: "所属店铺"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "员工姓名"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "username",
      label: "账号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "phone",
      label: "手机号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "date_joined",
      label: "注册时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "300"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.checkStaff(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "danger",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.deleteStaff(scope.row);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "新增员工",
      visible: _vm.dialogFormVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    staticClass: "demo-ruleForm",
    attrs: {
      model: _vm.ruleForm,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "员工姓名",
      prop: "name",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "name", $$v);
      },
      expression: "ruleForm.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "账号",
      prop: "username",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm.username,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "username", $$v);
      },
      expression: "ruleForm.username"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "密码",
      prop: "password",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "password", $$v);
      },
      expression: "ruleForm.password"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "手机号",
      prop: "phone",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "phone", $$v);
      },
      expression: "ruleForm.phone"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      "label-width": _vm.labelWidth
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.addStaff("ruleForm");
      }
    }
  }, [_vm._v("确认添加")]), _vm._v(" "), _c("el-button", {
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")])], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "修改员工信息",
      visible: _vm.dialogFormVisible1
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible1 = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm1",
    staticClass: "demo-ruleForm",
    attrs: {
      model: _vm.ruleForm1,
      rules: _vm.rules1
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "员工姓名",
      prop: "name",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm1.name,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm1, "name", $$v);
      },
      expression: "ruleForm1.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "账号",
      prop: "username",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm1.username,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm1, "username", $$v);
      },
      expression: "ruleForm1.username"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "管理订单",
      prop: "order_type",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm1.order_type == 2,
      expression: "ruleForm1.order_type==2"
    }],
    on: {
      click: function click($event) {
        return _vm.updateRules(1);
      }
    }
  }, [_vm._v("开启")]), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm1.order_type == 1,
      expression: "ruleForm1.order_type==1"
    }],
    on: {
      click: function click($event) {
        return _vm.updateRules(2);
      }
    }
  }, [_vm._v("关闭")])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      "label-width": _vm.labelWidth
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.editStaff("ruleForm1");
      }
    }
  }, [_vm._v("确认修改")]), _vm._v(" "), _c("el-button", {
    on: {
      click: _vm.cancel1
    }
  }, [_vm._v("取消")])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      "label-width": _vm.labelWidth
    }
  }, [_c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("如需修改密码，可前往首页——右上角——更多——选择修改密码，进行修改")])])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;