"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-page-header", {
    attrs: {
      title: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }), _vm._v(" "), _c("el-card", {
    staticStyle: {
      padding: "10px 0",
      "margin-top": "10px",
      "margin-bottom": "10px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    staticStyle: {
      height: "30px"
    },
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "margin-left": "20px",
      "font-weight": "600",
      "font-size": "20px"
    }
  }, [_vm._v("订单信息")])]), _vm._v(" "), _vm.document.is_cancel == 0 && (_vm.isAdmin || _vm.is_order_close) ? _c("el-button", {
    staticClass: "filter-item",
    staticStyle: {
      float: "right",
      "margin-right": "10px"
    },
    attrs: {
      type: "danger",
      icon: "el-icon-finished",
      size: "small"
    },
    on: {
      click: _vm.onSubmitOrder
    }
  }, [_vm._v("结束订单")]) : _vm._e(), _vm._v(" "), _vm.document.is_esign_contract == 1 && _vm.document.is_alipay_contract == 1 && (_vm.isAdmin || _vm.is_order_settle) && _vm.document.is_cancel == 0 ? _c("el-button", {
    staticClass: "filter-item",
    staticStyle: {
      float: "right",
      "margin-right": "10px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-thumb",
      size: "small"
    },
    on: {
      click: _vm.checkOrder
    }
  }, [_vm._v("生成支付二维码")]) : _vm._e(), _vm._v(" "), _c("el-button", {
    staticClass: "filter-item",
    staticStyle: {
      float: "right",
      "margin-right": "10px"
    },
    attrs: {
      type: "danger",
      icon: "el-icon-finished",
      size: "small"
    },
    on: {
      click: _vm.onSubmitPayEmw
    }
  }, [_vm._v("生成订单服务费用二维码")]), _vm._v(" "), _c("div", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "item"
  }, [_vm._v("订单号: "), _c("copy-tag", {
    attrs: {
      content: _vm.document.orderNo
    }
  }), _vm._v(" "), _c("button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAdmin || _vm.document.is_esign_contract == 0,
      expression: "isAdmin || document.is_esign_contract == 0"
    }],
    staticClass: "refresh-btn",
    on: {
      click: _vm.refreshOrderNo
    }
  }, [_c("i", {
    staticClass: "el-icon-refresh-right"
  }), _vm._v("重新生成订单号")]), _vm._v(" "), _vm.document.is_esign_contract == 1 && _vm.document.is_alipay_contract == 1 && _vm.document.is_pay == 1 && _vm.document.is_history == 0 ? _c("el-button", {
    staticClass: "filter-item",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-thumb",
      size: "small"
    },
    on: {
      click: _vm.checkHK
    }
  }, [_vm._v("同步代扣列表")]) : _vm._e(), _vm._v(" "), (_vm.document.is_esign_contract == 0 || _vm.document.is_alipay_contract == 0) && _vm.document.is_pay == 1 && _vm.document.is_cancel == 0 && _vm.document.is_history == 0 && _vm.document.esign_contract_signNo ? _c("el-button", {
    staticClass: "filter-item",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-thumb",
      size: "small"
    },
    on: {
      click: _vm.checkSign
    }
  }, [_vm._v("同步合约信息")]) : _vm._e()], 1), _vm._v(" "), _c("el-descriptions", {
    attrs: {
      title: "",
      direction: "vertical",
      column: 4,
      border: ""
    }
  }, [_c("el-descriptions-item", {
    attrs: {
      label: "创建时间"
    }
  }, [_vm._v(_vm._s(_vm.document.create_time))]), _vm._v(" "), _c("el-descriptions-item", {
    attrs: {
      label: "租赁合同签署状态"
    }
  }, [_vm._v(_vm._s(_vm.document.is_esign_contract == 0 ? "未签署" : "已签署") + " "), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.document.is_esign_contract == 1,
      expression: "document.is_esign_contract == 1"
    }],
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.upload
    }
  }, [_vm._v("下载租赁合同")]), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.document.is_esign_contract == 0,
      expression: "document.is_esign_contract == 0"
    }],
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.orderSignContract
    }
  }, [_vm._v("签署合同二维码")])], 1), _vm._v(" "), _c("el-descriptions-item", {
    attrs: {
      label: "代扣协议签署状态"
    }
  }, [_vm._v(_vm._s(_vm.document.is_alipay_contract == 0 ? "未签署" : "已签署") + "\n          "), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.document.is_alipay_contract == 0,
      expression: "document.is_alipay_contract == 0"
    }],
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.orderSignOrder
    }
  }, [_vm._v("签署协议二维码")])], 1), _vm._v(" "), _c("el-descriptions-item", {
    attrs: {
      label: "订单金额"
    }
  }, [_vm._v(_vm._s(_vm.document.money) + "元")]), _vm._v(" "), _c("el-descriptions-item", {
    attrs: {
      label: "用户已支付金额",
      contentStyle: "font-weight: 600;color:red"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.document.pay_money, 2)) + "元")]), _vm._v(" "), _c("el-descriptions-item", {
    attrs: {
      label: "订单状态"
    }
  }, [_vm._v(_vm._s(_vm.document.is_cancel == 1 ? "已取消" : _vm.document.is_history == 1 ? "已结束" : "进行中"))])], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "结算订单",
      visible: _vm.dialogVisibleAccount,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisibleAccount = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "订单总金额"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.document.money > 0 && _vm.document.money.toFixed(2)) + "元\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "结算金额",
      prop: "recharge"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.checkOrderMoney,
      callback: function callback($$v) {
        _vm.checkOrderMoney = $$v;
      },
      expression: "checkOrderMoney"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _vm._v(" "), _c("el-form-item", [_c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("确认收款后，商户需手动结束订单以终止划扣")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmitAccount
    }
  }, [_vm._v("生成支付二维码")])], 1)], 1)], 1), _vm._v(" "), _c("el-card", {
    staticStyle: {
      "margin-bottom": "10px",
      "padding-bottom": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      height: "40px",
      "line-height": "40px"
    },
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "margin-left": "20px",
      "font-weight": "600",
      "font-size": "20px"
    }
  }, [_vm._v("订单流水")])]), _vm._v(" "), _c("div", {
    staticClass: "list"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.orderList,
      border: "",
      stripe: "",
      "header-cell-style": {
        color: "#fff",
        background: "#409eff",
        fontWeight: "700"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "rendNo",
      label: "划扣单号",
      width: "300"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "orderNo",
      label: "订单号",
      width: "270"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "create_time",
      label: "创建时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "详情"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "money",
      label: "金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.money) + "元")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "is_user",
      label: "承担方"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.is_user == 0 ? _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v("用户承担")]) : _c("el-tag", [_vm._v("商家承担")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "is_pay",
      label: "支付状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.is_pay == 0 && scope.row.remark != "用户线下支付" ? _c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v("未支付")]) : scope.row.is_pay == 0 && scope.row.remark == "用户线下支付" ? _c("span") : _c("span", [_vm._v("\n              已支付\n              "), _c("el-button", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: (_vm.document.shop == 25 || _vm.document.shop == 42) && scope.row.is_user == 0 && (scope.row.remark == "订单冻结金" || scope.row.remark == "订单服务费用" || scope.row.remark == "合同费用"),
            expression: "(document.shop == 25 || document.shop == 42) && scope.row.is_user == 0 && (scope.row.remark == '订单冻结金' || scope.row.remark == '订单服务费用' || scope.row.remark == '合同费用')"
          }],
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.returnPay(scope.row.rendNo);
            }
          }
        }, [_vm._v("退款")])], 1)];
      }
    }])
  })], 1)], 1)]), _vm._v(" "), _c("el-card", {
    staticStyle: {
      "margin-bottom": "10px",
      "padding-bottom": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      height: "40px",
      "line-height": "40px"
    },
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "margin-left": "20px",
      "font-weight": "600",
      "font-size": "20px"
    }
  }, [_vm._v("订单分期")]), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAdmin || _vm.is_cancle,
      expression: "isAdmin || is_cancle"
    }],
    staticStyle: {
      float: "right"
    },
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.cancelRendlist
    }
  }, [_vm._v("批量取消扣款周期")])], 1), _vm._v(" "), _c("div", {
    staticClass: "list"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.periodList.results,
      border: "",
      stripe: "",
      "header-cell-style": {
        color: "#fff",
        background: "#409eff",
        fontWeight: "700"
      },
      "highlight-selection-row": ""
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "q_num",
      label: "期数"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v("第" + _vm._s(scope.row.q_num) + "期")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "create_time",
      label: "创建时间"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "hkNo",
      label: "分期单号",
      width: "300"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "money",
      label: "金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.money) + "元")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "cost_day",
      label: "计划扣款日期"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "扣款状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.status == true ? _c("span", [_vm._v("已扣款")]) : scope.row.is_cancel == true ? _c("span", [_vm._v("已取消")]) : scope.row.status == false && scope.row.alipay_id == "0000" ? _c("span", [_vm._v("扣款失败")]) : scope.row.status == false && scope.row.alipay_id == "NOT_RETURN" ? _c("span", [_vm._v("扣款失败")]) : scope.row.status == false && scope.row.alipay_id == "OVERDUE" ? _c("span", [_vm._v("逾期")]) : _c("span", [_vm._v("待扣款")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "content",
      label: "扣款说明"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.content))])];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.periodList.count > 0,
      expression: "periodList.count > 0"
    }],
    staticStyle: {
      "margin-top": "10px",
      "text-align": "center"
    },
    attrs: {
      total: _vm.periodList.count,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.page_size,
      autoScroll: false
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "page_size", $event);
      },
      pagination: _vm.getPeriodList
    }
  })], 1)]), _vm._v(" "), _c("el-card", {
    staticStyle: {
      padding: "10px 0",
      "margin-top": "10px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    staticStyle: {
      height: "30px"
    },
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "margin-left": "20px",
      "font-weight": "600",
      "font-size": "20px"
    }
  }, [_vm._v("用户信息")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      type: "text"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisibleUser = true;
      }
    }
  }, [_vm._v("修改用户信息")])], 1), _vm._v(" "), _c("div", {
    staticClass: "list user_list"
  }, [_c("div", {
    staticClass: "item user_item"
  }, [_c("img", {
    staticStyle: {
      width: "60px",
      height: "60px"
    },
    attrs: {
      src: require("../../assets/avatar.png"),
      alt: ""
    }
  })]), _vm._v(" "), _c("div", {
    staticClass: "item user_item"
  }, [_vm._v("姓名："), _c("span", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.userInfo.real_name))])]), _vm._v(" "), _c("div", {
    staticClass: "item user_item"
  }, [_vm._v("手机号："), _c("span", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.userInfo.mobile))])]), _vm._v(" "), _c("div", {
    staticClass: "item user_item"
  }, [_vm._v("身份证号："), _c("span", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.userInfo.card_code))])]), _vm._v(" "), _c("div", {
    staticClass: "item user_item"
  }, [_vm._v("签约店铺总数："), _c("span", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.userInfo.shopNum) + "家")])]), _vm._v(" "), _c("div", {
    staticClass: "item user_item"
  }, [_vm._v("签约订单总数："), _c("span", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.userInfo.orderNum) + "笔")])]), _vm._v(" "), _c("div", {
    staticClass: "item user_item"
  }, [_vm._v("签约金额总数："), _c("span", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.userInfo.orderSum) + "元")])])])]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "修改用户信息",
      visible: _vm.dialogVisibleUser,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisibleUser = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    attrs: {
      "label-width": "120px"
    },
    model: {
      value: _vm.userform,
      callback: function callback($$v) {
        _vm.userform = $$v;
      },
      expression: "userform"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "姓名"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.userform.name,
      callback: function callback($$v) {
        _vm.$set(_vm.userform, "name", $$v);
      },
      expression: "userform.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "身份证号"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入18位有效身份证号码，字母要大写"
    },
    model: {
      value: _vm.userform.card_code,
      callback: function callback($$v) {
        _vm.$set(_vm.userform, "card_code", $$v);
      },
      expression: "userform.card_code"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("请务必填写真实姓名和身份证号，以免影响后续签约")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateUserInfo
    }
  }, [_vm._v("确认修改")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;