"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatMixin = void 0;
var formatMixin = exports.formatMixin = {
  methods: {
    // 向下取整，保留2位小数
    formatNum: function formatNum(num, decimals) {
      var roundedNum = (Math.floor(num * 100) / 100).toFixed(decimals);
      return roundedNum;
    }
  }
};